import { resource, types } from './accessControl';

const actionsPermissions = {
  testPlayers: [
    {
      type: types.manage,
      rule: `${resource.players}.${resource.testPlayers}`,
    },
    {
      type: types.manage,
      rule: `${resource.players}.${resource.testPlayers}`,
    },
    {
      type: types.manage,
      rule: `${resource.players}.${resource.testPlayers}`,
    },
  ],
  kyc: [
    {
      type: types.manage,
      rule: `${resource.players}.${resource.kyc}`,
    },
    {
      type: types.manage,
      rule: `${resource.players}.${resource.kyc}`,
    },
    {
      type: types.view,
      rule: `${resource.players}.${resource.kyc}`,
    },
  ],
  deposits: [
    {
      type: types.manage,
      rule: `${resource.financials}.${resource.deposits}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.financials}.${resource.deposits}`,
    },
    {
      type: types.manage,
      rule: `${resource.financials}.${resource.deposits}`,
    },
    {
      type: types.view,
      rule: `${resource.financials}.${resource.deposits}`,
    },
  ],
  playerDeposits: [
    {
      type: types.manage,
      rule: `${resource.players}.${resource.playerDeposits}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.players}.${resource.playerDeposits}`,
    },
  ],
  withdrawals: [
    {
      type: types.approve,
      rule: `${resource.financials}.${resource.withdrawals}`,
    },
    {
      type: types.cancel,
      rule: `${resource.financials}.${resource.withdrawals}`,
    },
    {
      type: types.freeze,
      rule: `${resource.financials}.${resource.withdrawals}`,
    },
    {
      type: types.startProcess,
      rule: `${resource.financials}.${resource.withdrawals}`,
    },
    {
      type: types.manage,
      rule: `${resource.financials}.${resource.withdrawals}`,
    },
    {
      type: types.decline,
      rule: `${resource.financials}.${resource.withdrawals}`,
    },
    {
      type: types.manage,
      rule: `${resource.financials}.${resource.withdrawals}`,
    },
    {
      type: types.view,
      rule: `${resource.financials}.${resource.withdrawals}`,
    },
  ],
  playerWithdrawals: [
    {
      type: types.approve,
      rule: `${resource.players}.${resource.playerWithdrawals}`,
    },
    {
      type: types.cancel,
      rule: `${resource.players}.${resource.playerWithdrawals}`,
    },
    {
      type: types.freeze,
      rule: `${resource.players}.${resource.playerWithdrawals}`,
    },
    {
      type: types.startProcess,
      rule: `${resource.players}.${resource.playerWithdrawals}`,
    },
    {
      type: types.manage,
      rule: `${resource.players}.${resource.playerWithdrawals}`,
    },
    {
      type: types.decline,
      rule: `${resource.players}.${resource.playerWithdrawals}`,
    },
  ],
  games: [
    {
      type: types.view,
      rule: `${resource.contentManagement}.${resource.games}`,
    },
    {
      type: types.view,
      rule: `${resource.contentManagement}.${resource.games}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.contentManagement}.${resource.games}`,
    },
    {
      type: types.edit,
      rule: `${resource.contentManagement}.${resource.games}`,
    },
    {
      type: types.edit,
      rule: `${resource.contentManagement}.${resource.games}`,
    },
  ],
  gameGroups: [
    {
      type: types.edit,
      rule: `${resource.contentManagement}.${resource.groups}`,
    },
    {
      type: types.delete,
      rule: `${resource.contentManagement}.${resource.groups}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.contentManagement}.${resource.groups}`,
    },
  ],
  gameTypes: [
    {
      type: types.manage,
      rule: `${resource.contentManagement}.${resource.gameTypes}`,
    },
    {
      type: types.delete,
      rule: `${resource.contentManagement}.${resource.gameTypes}`,
    },
    {
      type: types.manage,
      rule: `${resource.contentManagement}.${resource.gameTypes}`,
    },
  ],
  users: [
    {
      type: types.manage,
      rule: `${resource.userManagement}.${resource.users}`,
    },
    {
      type: types.manage,
      rule: `${resource.userManagement}.${resource.users}`,
    },
    {
      type: types.edit,
      rule: `${resource.userManagement}.${resource.users}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.userManagement}.${resource.users}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.userManagement}.${resource.users}`,
    },
    {
      type: types.delete,
      rule: `${resource.userManagement}.${resource.users}`,
    },
    {
      type: types.view,
      rule: `${resource.userManagement}.${resource.users}`,
    },
  ],
  brandPaymentMethods: [
    {
      type: types.edit,
      rule: `${resource.brands}.${resource.payments}`,
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.payments}`,
    },
  ],
  cashBacks: [
    {
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
    },
    {
      type: types.edit,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
    },
    {
      type: types.delete,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
    },
    {
      type: types.manage,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
    },
  ],
  referrals: [
    {
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.referralBonus}`,
    },
    {
      type: types.edit,
      rule: `${resource.bonusManagement}.${resource.referralBonus}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.bonusManagement}.${resource.referralBonus}`,
    },
    {
      type: types.delete,
      rule: `${resource.bonusManagement}.${resource.referralBonus}`,
    },
    {
      type: types.manage,
      rule: `${resource.bonusManagement}.${resource.referralBonus}`,
    },
  ],
  reasons: [
    {
      type: types.edit,
      rule: `${resource.tools}.${resource.reasonsSystem}`,
    },
    {
      type: types.edit,
      rule: `${resource.tools}.${resource.reasonsSystem}`,
    },
    {
      type: types.delete,
      rule: `${resource.tools}.${resource.reasonsSystem}`,
    },
  ],
  resourceReasons: [
    {
      type: types.edit,
      rule: `${resource.tools}.${resource.reasonsSystem}`,
    },
    {
      type: types.delete,
      rule: `${resource.tools}.${resource.reasonsSystem}`,
    },
  ],
  campaigns: [
    {
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
    {
      type: types.add,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
    {
      type: types.manage,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
    {
      type: types.manage,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
    {
      type: types.edit,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
    {
      type: types.manage,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
  ],
  campaignsFinishDelete: [
    {
      type: types.manage,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
    {
      type: types.delete,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
  ],
  crossPlatformCampaigns: [
    {
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
    {
      type: types.add,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
    {
      type: types.manage,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
    {
      type: types.manage,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
    {
      type: types.manage,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
    {
      type: types.manage,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
  ],
  brandSportsBookWidgets: [
    {
      type: types.edit,
      rule: `${resource.brands}.${resource.sportsBook}`,
    },
  ],
  brandSportsBookSettings: [
    {
      type: types.edit,
      rule: `${resource.brands}.${resource.sportsBook}`,
    },
  ],
  brandSportsBookMobileIntegration: [
    {
      type: types.edit,
      rule: `${resource.brands}.${resource.sportsBook}`,
    },
  ],
  brandSportsBookStatusIcons: [
    {
      type: types.manage,
      rule: `${resource.brands}.${resource.sportsBook}`,
    },
  ],
  largeTransactionLogs: [
    {
      type: types.view,
      rule: `${resource.brands}.${resource.largeTransactions}`,
    },
  ],
  casinoBets: [
    {
      type: types.view,
      rule: `${resource.bets}.${resource.casinoBets}`,
    },
  ],
  sportBets: [
    {
      type: types.view,
      rule: `${resource.bets}.${resource.sportBets}`,
    },
  ],
  brandBlockedCountry: [
    {
      type: types.delete,
      rule: `${resource.brands}.${resource.locationServices}`,
    },
  ],
  brandBlockedIP: [
    {
      type: types.delete,
      rule: `${resource.brands}.${resource.locationServices}`,
    },
  ],
  brandCountry: [
    {
      type: types.edit,
      rule: `${resource.brands}.${resource.mainSettings}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.brands}.${resource.mainSettings}`,
    },
  ],
  brandCurrency: [
    {
      type: types.edit,
      rule: `${resource.brands}.${resource.mainSettings}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.brands}.${resource.mainSettings}`,
    },
  ],
  brandDomain: [
    {
      type: types.delete,
      rule: `${resource.brands}.${resource.domainConfigurations}`,
    },
    {
      type: types.manage,
      rule: `${resource.brands}.${resource.domainConfigurations}`,
    },
    {
      type: types.manage,
      rule: `${resource.brands}.${resource.domainConfigurations}`,
    },
  ],
  brandLanguage: [
    {
      type: types.edit,
      rule: `${resource.brands}.${resource.mainSettings}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.brands}.${resource.mainSettings}`,
    },
  ],
  brandEmailNotification: [
    {
      type: types.delete,
      rule: `${resource.brands}.${resource.notificationSettings}`,
    },
    {
      type: types.edit,
      rule: `${resource.brands}.${resource.notificationSettings}`,
    },
  ],
  brandPreferencesNotification: [
    {
      type: types.edit,
      rule: `${resource.brands}.${resource.notificationSettings}`,
    },
  ],
  brandLargeTransactions: [
    {
      type: types.edit,
      rule: `${resource.brands}.${resource.largeTransactions}`,
    },
  ],
  brandSeo: [
    {
      type: types.edit,
      rule: `${resource.brands}.${resource.seoSettings}`,
    },
  ],
  brandSportsBookLayout: [
    {
      type: types.statusChange,
      rule: `${resource.brands}.${resource.sportsBook}`,
    },
    {
      type: types.view,
      rule: `${resource.brands}.${resource.sportsBook}`,
    },
  ],
  playerHorizontalTags: [
    {
      type: types.delete,
      rule: `${resource.players}.${resource.playerHorizontalTags}`,
    },
  ],
  brandCustomScripts: [
    {
      type: types.edit,
      rule: `${resource.brands}.${resource.thirdPartyServices}`,
    },
    {
      type: types.delete,
      rule: `${resource.brands}.${resource.thirdPartyServices}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.brands}.${resource.thirdPartyServices}`,
    },
  ],
  categoryPaymentMethods: [
    {
      type: types.statusChange,
      rule: `${resource.contentManagement}.${resource.cashierDepositAndWithdrawal}`,
    },
  ],
  cashierDepositWithdrawal: [
    {
      type: types.edit,
      rule: `${resource.contentManagement}.${resource.cashierDepositAndWithdrawal}`,
    },
    {
      type: types.delete,
      rule: `${resource.contentManagement}.${resource.cashierDepositAndWithdrawal}`,
    },
  ],
  cashierInventory: [
    {
      type: types.edit,
      rule: `${resource.contentManagement}.${resource.cashierInventory}`,
    },
  ],
  draftMassMessages: [
    {
      type: types.edit,
      rule: `${resource.communications}.${resource.massMessages}`,
    },
    {
      type: types.delete,
      rule: `${resource.communications}.${resource.massMessages}`,
    },
  ],
  scheduledMassMessages: [
    {
      type: types.view,
      rule: `${resource.communications}.${resource.massMessages}`,
    },
    {
      type: types.edit,
      rule: `${resource.communications}.${resource.massMessages}`,
    },
    {
      type: types.delete,
      rule: `${resource.communications}.${resource.massMessages}`,
    },
  ],
  sentMassMessages: [
    {
      type: types.view,
      rule: `${resource.communications}.${resource.massMessages}`,
    },
  ],
  playerCasinoBets: [
    {
      type: types.view,
      rule: `${resource.players}.${resource.playerCasinoBets}`,
    },
  ],
  playerSportBets: [
    {
      type: types.view,
      rule: `${resource.bets}.${resource.sportBets}`,
    },
  ],
  playerBonuses: [
    {
      type: types.manage,
      rule: `${resource.players}.${resource.playerBonuses}`,
    },
    {
      type: types.view,
      rule: `${resource.players}.${resource.playerBonuses}`,
    },
  ],
  playerCommsMassMessages: [
    {
      type: types.view,
      rule: `${resource.players}.${resource.playerMassMessages}`,
    },
  ],
  playerSystemTemplates: [
    {
      type: types.view,
      rule: `${resource.players}.${resource.systemNotifications}`,
    },
  ],
  playerAttachmentHistory: [
    {
      type: types.view,
      rule: `${resource.players}.${resource.kyc}`,
    },
  ],
  playerBonusNotifications: [
    {
      type: types.view,
      rule: `${resource.players}.${resource.playerBonusNotifications}`,
    },
  ],
  playerMarketing: [
    {
      type: types.edit,
      rule: `${resource.tools}.${resource.playerSegments}`,
    },
  ],
  playerVerticalTags: [
    {
      type: types.delete,
      rule: `${resource.players}.${resource.playerVerticalTags}`,
    },
  ],
  horizontalTagCategories: [
    {
      type: types.edit,
      rule: `${resource.tools}.${resource.horizontalTags}`,
    },
    {
      type: types.delete,
      rule: `${resource.tools}.${resource.horizontalTags}`,
    },
  ],
  verticalTagCategories: [
    {
      type: types.edit,
      rule: `${resource.tools}.${resource.verticalTags}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.tools}.${resource.verticalTags}`,
    },
    {
      type: types.delete,
      rule: `${resource.tools}.${resource.verticalTags}`,
    },
  ],
  blacklist: [
    {
      type: types.delete,
      rule: `${resource.riskManagement}.${resource.blacklist}`,
    },
  ],
  brandAutomationRules: [
    {
      type: types.edit,
      rule: `${resource.tools}.${resource.brandAutomation}`,
    },
    {
      type: types.manage,
      rule: `${resource.tools}.${resource.brandAutomation}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.tools}.${resource.brandAutomation}`,
    },
    {
      type: types.delete,
      rule: `${resource.tools}.${resource.brandAutomation}`,
    },
  ],
  legalAgreements: [
    {
      type: types.edit,
      rule: `${resource.brands}.${resource.legal}`,
    },
    {
      type: types.delete,
      rule: `${resource.brands}.${resource.legal}`,
    },
  ],
  brandStandard: [
    {
      type: types.statusChange,
      rule: `${resource.brands}.${resource.standards}`,
    },
  ],
  gamesSegments: [
    {
      type: types.edit,
      rule: `${resource.tools}.${resource.gameSegments}`,
    },
    {
      type: types.delete,
      rule: `${resource.tools}.${resource.gameSegments}`,
    },
  ],
  horizontalTagPlayers: [
    {
      type: types.manage,
      rule: `${resource.tools}.${resource.horizontalTags}`,
    },
  ],
  horizontalTags: [
    {
      type: types.edit,
      rule: `${resource.tools}.${resource.horizontalTags}`,
    },
    {
      type: types.delete,
      rule: `${resource.tools}.${resource.horizontalTags}`,
    },
  ],
  ipWhitelist: [
    {
      type: types.delete,
      rule: `${resource.userManagement}.${resource.ipWhitelist}`,
    },
  ],
  partners: [
    {
      type: types.edit,
      rule: `${resource.partners}.${resource.partners}`,
    },
    {
      type: types.manage,
      rule: `${resource.partners}.${resource.manageMaintenance}`,
    },
    {
      type: types.manage,
      rule: `${resource.partners}.${resource.manageMaintenance}`,
    },
  ],
  permissionGroups: [
    {
      type: types.view,
      rule: `${resource.userManagement}.${resource.users}`,
    },
    {
      type: types.edit,
      rule: `${resource.userManagement}.${resource.permissionGroups}`,
    },
    {
      type: types.delete,
      rule: `${resource.userManagement}.${resource.permissionGroups}`,
    },
  ],
  playersSegments: [
    {
      type: types.edit,
      rule: `${resource.tools}.${resource.playerSegments}`,
    },
    {
      type: types.delete,
      rule: `${resource.tools}.${resource.playerSegments}`,
    },
  ],
  referralParticipants: [
    {
      type: types.edit,
      rule: `${resource.bonusManagement}.${resource.referralBonus}`,
    },
  ],
  segmentGames: [
    {
      type: types.edit,
      rule: `${resource.tools}.${resource.gameSegments}`,
    },
  ],
  segmentPlayers: [
    {
      type: types.edit,
      rule: `${resource.tools}.${resource.playerSegments}`,
    },
  ],
  systemConversions: [
    {
      type: types.edit,
      rule: `${resource.systemSettings}.${resource.systemConversion}`,
    },
  ],
  systemTemplates: [
    {
      type: types.edit,
      rule: `${resource.communications}.${resource.systemNotifications}`,
    },
    {
      type: types.view,
      rule: `${resource.communications}.${resource.systemNotifications}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.communications}.${resource.systemNotifications}`,
    },
  ],
  testPlayerUsers: [
    {
      type: types.manage,
      rule: `${resource.players}.${resource.testPlayers}`,
    },
    {
      type: types.manage,
      rule: `${resource.players}.${resource.testPlayers}`,
    },
  ],
  verticalTagPlayers: [
    {
      type: types.manage,
      rule: `${resource.tools}.${resource.verticalTags}`,
    },
  ],
  verticalTags: [
    {
      type: types.edit,
      rule: `${resource.tools}.${resource.verticalTags}`,
    },
    {
      type: types.delete,
      rule: `${resource.tools}.${resource.verticalTags}`,
    },
    {
      type: types.manage,
      rule: `${resource.tools}.${resource.verticalTags}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.tools}.${resource.verticalTags}`,
    },
  ],
  bonuses: [
    {
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
    {
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
  ],
  agSentData: [
    {
      type: types.edit,
      rule: `${resource.tools}.${resource.agSentData}`,
    },
  ],
  lobbyManagement: [
    {
      type: types.view,
      rule: `${resource.contentManagement}.${resource.lobbyManagement}`,
    },
    {
      type: types.edit,
      rule: `${resource.contentManagement}.${resource.lobbyManagement}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.contentManagement}.${resource.lobbyManagement}`,
    },
    {
      type: types.manage,
      rule: `${resource.contentManagement}.${resource.providersAndGamesManagement}`,
    },
  ],
  lobbyProviders: [
    {
      type: types.edit,
      rule: `${resource.contentManagement}.${resource.providersAndGamesManagement}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.contentManagement}.${resource.providersAndGamesManagement}`,
    },
    {
      type: types.manage,
      rule: `${resource.contentManagement}.${resource.providersAndGamesManagement}`,
    },
  ],
  promotions: [
    {
      type: types.view,
      rule: `${resource.gamification}.${resource.gamification}`,
    },
    {
      type: types.edit,
      rule: `${resource.gamification}.${resource.gamification}`,
    },
    {
      type: types.add,
      rule: `${resource.gamification}.${resource.gamification}`,
    },
    {
      type: types.manage,
      rule: `${resource.gamification}.${resource.gamification}`,
    },
  ],
  promotionsFinishDelete: [
    {
      type: types.finish,
      rule: `${resource.gamification}.${resource.gamification}`,
    },
    {
      type: types.delete,
      rule: `${resource.gamification}.${resource.gamification}`,
    },
  ],
  bonusStatistics: [
    {
      type: types.manage,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
  ],
  significantEvents: [
    {
      type: types.manage,
      rule: `${resource.romanianRegulatory}.${resource.romanianRegulatory}`,
    },
  ],
  brandCasinoProductSettings: [
    {
      type: types.edit,
      rule: `${resource.brands}.${resource.casinoSettings}`,
    },
  ],
  brandJackpotFeeds: [
    {
      type: types.statusChange,
      rule: `${resource.brands}.${resource.brandJackpots}`,
    },
  ],
  sentPushNNotifications: [
    {
      type: types.view,
      rule: `${resource.communications}.${resource.pushNotifications}`,
    },
  ],
  scheduledPushNNotifications: [
    {
      type: types.view,
      rule: `${resource.communications}.${resource.pushNotifications}`,
    },
    {
      type: types.delete,
      rule: `${resource.communications}.${resource.pushNotifications}`,
    },
  ],
  bettingSelfProtection: [
    {
      type: types.export,
      rule: `${resource.cypriotRegulatory}.${resource.bettingAndSelfProtection}`,
    },
  ],
  gameTypeGames: [
    {
      type: types.manage,
      rule: `${resource.contentManagement}.${resource.gameTypes}`,
    },
  ],
  lobbyGameGroups: [
    {
      type: types.manage,
      rule: `${resource.contentManagement}.${resource.groupsManagement}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.contentManagement}.${resource.groupsManagement}`,
    },
  ],
  widgets: [
    {
      type: types.view,
      rule: `${resource.contentManagement}.${resource.widgets}`,
    },
    {
      type: types.manage,
      rule: `${resource.contentManagement}.${resource.widgets}`,
    },
    {
      type: types.statusChange,
      rule: `${resource.contentManagement}.${resource.widgets}`,
    },
  ],
  promotionPrize: [
    {
      type: types.view,
      rule: `${resource.gamification}.${resource.gamification}`,
    },
    {
      type: types.view,
      rule: `${resource.gamification}.${resource.gamification}`,
    },
  ],
  playerComplaints: [
    {
      type: types.view,
      rule: `${resource.communications}.${resource.playerComplaints}`,
    },
    {
      type: types.manage,
      rule: `${resource.communications}.${resource.playerComplaints}`,
    },
  ],
  crossPlatformCampaignsV2: [
    {
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
    {
      type: types.manage,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
    {
      type: types.manage,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
  ],
  crossPlatformCampaignsV2Statistics: [
    {
      type: types.manage,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
  ],
  campaignsV2: [
    {
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
    {
      type: types.add,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
    {
      type: types.add,
      rule: `${resource.bonusManagement}.${resource.bonus}`,
    },
  ],
  cashBacksV2: [
    {
      type: types.view,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
    },
    // {
    //   type: types.edit,
    //   rule: `${resource.bonusManagement}.${resource.cashback}`,
    // },
    {
      type: types.statusChange,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
    },
    {
      type: types.delete,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
    },
    {
      type: types.manage,
      rule: `${resource.bonusManagement}.${resource.cashback}`,
    },
  ],
};

export default actionsPermissions;

import RegulatoryActionType from 'actions/regulatory/regulatoryActionType';

const initialState = {
  softwareChanges: {
    data: [],
    total: 0,
  },
  gameRatio: {
    data: [],
    total: 0,
  },
  playerExclusion: {
    data: [],
    total: 0,
  },
  gameTypes: {
    data: [],
    total: 0,
  },
  balanceAdjustment: {
    data: [],
    total: 0,
  },
  sessions: {
    data: [],
    total: 0,
  },
  mirroringServer: {
    data: [],
    total: 0,
  },
  safeServer: {
    data: [],
    total: 0,
  },
  changeNotification: {
    data: [],
    total: 0,
  },
  inaccessibilityServer: {
    data: [],
    total: 0,
  },
  largeTransactions: {
    data: [],
    total: 0,
  },
  restrictions: {
    data: [],
    total: 0,
  },
  jackpotWinRatio: {
    data: [],
    total: 0,
  },
  jackpotConfiguration: {
    data: [],
    total: 0,
  },
  promotionalItems: {
    data: [],
    total: 0,
  },
  taxationDaily: {
    data: [],
    total: 0,
  },
  taxationMonthly: [],
  greeceReport: {
    data: [],
    total: 0,
  },
};
const regulatoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case RegulatoryActionType.SAVE_SOFTWARE_CHANGES:
    return {
      ...state,
      softwareChanges: payload.data,
    };
  case RegulatoryActionType.RESET_SOFTWARE_CHANGES:
    return {
      ...state,
      softwareChanges: {
        data: [],
        total: 0,
      },
    };
  case RegulatoryActionType.SAVE_GAME_RATIO:
    return {
      ...state,
      gameRatio: payload.data,
    };
  case RegulatoryActionType.RESET_GAME_RATIO:
    return {
      ...state,
      gameRatio: {
        data: [],
        total: 0,
      },
    };
  case RegulatoryActionType.SAVE_PLAYER_EXCLUSION:
    return {
      ...state,
      playerExclusion: payload.data,
    };
  case RegulatoryActionType.RESET_PLAYER_EXCLUSION:
    return {
      ...state,
      playerExclusion: {
        data: [],
        total: 0,
      },
    };
  case RegulatoryActionType.SAVE_GAME_TYPES_REPORT:
    return {
      ...state,
      gameTypes: payload.data,
    };
  case RegulatoryActionType.RESET_GAME_TYPES_REPORT:
    return {
      ...state,
      gameTypes: {
        data: [],
        total: 0,
      },
    };
  case RegulatoryActionType.SAVE_BALANCE_ADJUSTMENT:
    return {
      ...state,
      balanceAdjustment: payload.data,
    };
  case RegulatoryActionType.RESET_BALANCE_ADJUSTMENT:
    return {
      ...state,
      balanceAdjustment: {
        data: [],
        total: 0,
      },
    };
  case RegulatoryActionType.SAVE_SESSIONS:
    return {
      ...state,
      sessions: payload.data,
    };
  case RegulatoryActionType.RESET_SESSIONS:
    return {
      ...state,
      sessions: {
        data: [],
        total: 0,
      },
    };
  case RegulatoryActionType.SAVE_MIRRORING_SERVER:
    return {
      ...state,
      mirroringServer: payload.data,
    };
  case RegulatoryActionType.RESET_MIRRORING_SERVER:
    return {
      ...state,
      mirroringServer: {
        data: [],
        total: 0,
      },
    };
  case RegulatoryActionType.SAVE_SAFE_SERVER:
    return {
      ...state,
      safeServer: payload.data,
    };
  case RegulatoryActionType.RESET_SAFE_SERVER:
    return {
      ...state,
      safeServer: {
        data: [],
        total: 0,
      },
    };
  case RegulatoryActionType.SAVE_CHANGE_NOTIFICATION:
    return {
      ...state,
      changeNotification: payload.data,
    };
  case RegulatoryActionType.RESET_CHANGE_NOTIFICATION:
    return {
      ...state,
      changeNotification: {
        data: [],
        total: 0,
      },
    };
  case RegulatoryActionType.SAVE_INACCESSIBILITY_SERVER:
    return {
      ...state,
      inaccessibilityServer: payload.data,
    };
  case RegulatoryActionType.RESET_INACCESSIBILITY_SERVER:
    return {
      ...state,
      inaccessibilityServer: {
        data: [],
        total: 0,
      },
    };
  case RegulatoryActionType.SAVE_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS:
    return {
      ...state,
      largeTransactions: payload.data,
    };
  case RegulatoryActionType.RESET_SIGNIFICANT_EVENTS_LARGE_TRANSACTIONS:
    return {
      ...state,
      largeTransactions: {
        data: [],
        total: 0,
      },
    };
  case RegulatoryActionType.SAVE_SIGNIFICANT_EVENTS_RESTRICTIONS:
    return {
      ...state,
      restrictions: payload.data,
    };
  case RegulatoryActionType.RESET_SIGNIFICANT_EVENTS_RESTRICTIONS:
    return {
      ...state,
      restrictions: {
        data: [],
        total: 0,
      },
    };
  case RegulatoryActionType.SAVE_JACKPOT_WIN_RATIO:
    return {
      ...state,
      jackpotWinRatio: payload.data,
    };
  case RegulatoryActionType.RESET_JACKPOT_WIN_RATIO:
    return {
      ...state,
      jackpotWinRatio: {
        data: [],
        total: 0,
      },
    };
  case RegulatoryActionType.SAVE_JACKPOT_CONFIGURATION:
    return {
      ...state,
      jackpotConfiguration: payload.data,
    };
  case RegulatoryActionType.RESET_JACKPOT_CONFIGURATION:
    return {
      ...state,
      jackpotConfiguration: {
        data: [],
        total: 0,
      },
    };
  case RegulatoryActionType.SAVE_PROMOTIONAL_ITEMS:
    return {
      ...state,
      promotionalItems: payload.data,
    };
  case RegulatoryActionType.RESET_PROMOTIONAL_ITEMS:
    return {
      ...state,
      promotionalItems: {
        data: [],
        total: 0,
      },
    };
  case RegulatoryActionType.SAVE_TAXATION_DAILY:
    return {
      ...state,
      taxationDaily: payload.data,
    };
  case RegulatoryActionType.RESET_TAXATION_DAILY:
    return {
      ...state,
      taxationDaily: {
        data: [],
        total: 0,
      },
    };
  case RegulatoryActionType.SAVE_TAXATION_MONTHLY:
    return {
      ...state,
      taxationMonthly: payload.data,
    };
  case RegulatoryActionType.RESET_TAXATION_MONTHLY:
    return {
      ...state,
      taxationMonthly: [],
    };
  case RegulatoryActionType.SAVE_REGULATORY_REPORT:
    return {
      ...state,
      greeceReport: payload.data,
    };
  case RegulatoryActionType.RESET_REGULATORY_REPORT:
    return {
      ...state,
      greeceReport: {
        data: [],
        total: 0,
      },
    };
  default:
    return state;
  }
};

export default regulatoryReducer;

import OperatorLimitActionType from './operatorLimitActionType';

export const getLargeTransactionsGeneralLimits = (brandId, params) => ({
  type: OperatorLimitActionType.GET_LARGE_TRANSACTIONS_GENERAL_LIMITS,
  payload: { brandId, params },
});

export const saveLargeTransactionsGeneralLimits = (data) => ({
  type: OperatorLimitActionType.SAVE_LARGE_TRANSACTIONS_GENERAL_LIMITS,
  payload: { data },
});

export const resetLargeTransactionsGeneralLimits = () => ({
  type: OperatorLimitActionType.RESET_LARGE_TRANSACTIONS_GENERAL_LIMITS,
});

export const editLargeTransactionsGeneralLimits = (brandId, data) => ({
  type: OperatorLimitActionType.EDIT_LARGE_TRANSACTIONS_GENERAL_LIMITS,
  payload: { brandId, data },
});

export const getLargeTransactionLimitsLogs = (brandId, params) => ({
  type: OperatorLimitActionType.GET_LARGE_TRANSACTION_LIMITS_LOGS,
  payload: { brandId, params },
});

export const saveLargeTransactionLimitsLogs = (data) => ({
  type: OperatorLimitActionType.SAVE_LARGE_TRANSACTION_LIMITS_LOGS,
  payload: { data },
});

export const resetLargeTransactionLimitsLogs = () => ({
  type: OperatorLimitActionType.RESET_LARGE_TRANSACTION_LIMITS_LOGS,
});

export const getBrandPlayerLimits = (brandId) => ({
  type: OperatorLimitActionType.GET_BRAND_PLAYER_LIMITS,
  payload: { brandId },
});

export const saveBrandPlayerLimits = (data) => ({
  type: OperatorLimitActionType.SAVE_BRAND_PLAYER_LIMITS,
  payload: { data },
});

export const resetBrandPlayerLimits = () => ({
  type: OperatorLimitActionType.RESET_BRAND_PLAYER_LIMITS,
});

export const changeBrandPlayerLimits = (brandId, data) => ({
  type: OperatorLimitActionType.CHANGE_BRAND_PLAYER_LIMITS,
  payload: { brandId, data },
});

export const getResponsibleGamingLimits = (params) => ({
  type: OperatorLimitActionType.GET_RESPONSIBLE_GAMING_LIMITS,
  payload: { params },
});

export const saveResponsibleGamingLimits = (data) => ({
  type: OperatorLimitActionType.SAVE_RESPONSIBLE_GAMING_LIMITS,
  payload: { data },
});

export const resetResponsibleGamingLimits = () => ({
  type: OperatorLimitActionType.RESET_RESPONSIBLE_GAMING_LIMITS,
});

export const editResponsibleGamingLimits = (params, data) => ({
  type: OperatorLimitActionType.EDIT_RESPONSIBLE_GAMING_LIMITS,
  payload: { params, data },
});

export const getLargeTransactionsWinLimits = (brandId, params) => ({
  type: OperatorLimitActionType.GET_LARGE_TRANSACTIONS_WIN_LIMITS,
  payload: { brandId, params },
});

export const saveLargeTransactionsWinLimits = (data) => ({
  type: OperatorLimitActionType.SAVE_LARGE_TRANSACTIONS_WIN_LIMITS,
  payload: { data },
});

export const resetLargeTransactionsWinLimits = () => ({
  type: OperatorLimitActionType.RESET_LARGE_TRANSACTIONS_WIN_LIMITS,
});

export const editLargeTransactionsWinLimits = (brandId, data) => ({
  type: OperatorLimitActionType.EDIT_LARGE_TRANSACTIONS_WIN_LIMITS,
  payload: { brandId, data },
});

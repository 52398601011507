export default {
  en: {
    enableDisable: 'Are you sure you want to {{action}} <0>{{name}}</0>?',
    enableDisableModel: 'Are you sure you want to {{action}} <0>{{name}}</0> {{model}}?',
    finish: 'Are you sure you want to finish <0>{{name}}</0> {{model}}?',
    setMainCountry: 'Are you sure you want to set {{action}} <0>{{name}}</0> as main country?',
    setMainCurrency: 'Are you sure you want to set {{action}} <0>{{name}}</0> as main currency?',
    setMainLanguage: 'Are you sure you want to set {{action}} <0>{{name}}</0> as main language?',
    deleteRule: 'Are you sure you want to delete the rule?',
    enableDisableSportBook: 'Are you sure you want to enable <0>{{name}}</0>{{provider}}?',
    delete: 'Are you sure you want to delete the <0>{{name}}</0> {{model}}?',
    deleteByName: 'Are you sure you want to delete the <0>{{name}}</0>?',
    deleteByModel: 'Are you sure you want to delete the {{model}}?',
    enableGamesForAllBrands: 'Are you sure you want to enable game(s) for all brands?',
    enableCasinoGames: 'Are you sure you want to {{action}} <0>{{name}}</0> provider for all brands?',
    resetLargeTransactionValues: 'Are you sure you want to reset values for <0>{{name}}</0>?',
    deleteScript: 'Are you sure you want to delete the Script <0>{{name}}</0>?',
    resetSMS: 'Are you sure you want to <0>reset</0> SMS settings?',
    makeEmailAsDefault: 'Are you sure you want to make <0>{{name}}</0> email as default?',
    deleteCountry: 'Are you sure you want to delete <0>{{name}}</0> country from restriction list?',
    deleteBlockIp: 'Are you sure you want to delete <0>{{name}}</0> from the restriction list?',
    deleteVersion: 'Are you sure you want to delete the version?',
    cancel: 'Are you sure you want to cancel <0>{{name}}</0> {{model}}?',
    disconnect: 'Are you sure you want to disconnect trigger from <0>{{name}}</0> {{model}}?',
    emptyRule: 'Are you sure you want to empty <0>{{name}}</0> rule?',
    finishRule: 'Are you sure you want to finish <0>{{name}}</0> rule?',
    sendCashback: 'Are you sure you want to send CashBack for the following periods?',
    sendNoPeriodCashback: 'Are you sure you want to send CashBack to the selected players?',
    reSendCashback: 'Are you sure you want to resend CashBack for the following periods?',
    reSendNoPeriodCashback: 'Are you sure you want to resend CashBack to the selected players?',
    deleteCashback: 'Are you sure you want to delete CashBack for the following periods?',
    deletePlayerFromRestricted: 'Are you sure you want to delete <0>{{name}}</0> player from restriction list?',
    deletePlayerFromList: 'Are you sure you want to delete <0>{{name}}</0> player from list?',
    sendCommissions: 'Are you sure you want to <0>send</0> commissions?',
    reSendCommissions: 'Are you sure you want to <0>resend</0> commissions?',
    finishAccountUsage: 'Are you sure you want to finish use of the account?',
    unlockPlayer: 'Are you sure want to unlock this player?',
    blockPlayerForMessages: 'Are you sure you want to {{action}} player for messages?',
    blacklistPlayer: 'Are you sure you want to {{action}} the player?',
    activatePlayerAccount: "Are you sure  you want to activate player's account?",
    activatePlayerStatus: 'Are you sure you want to activate player {{action}}?',
    verifyPlayersAttribute: "Are you sure you want to {{action}} player's {{attribute}}?",
    completePhysicalVerification: 'Are you sure you want to set physical verification as {{action}}?',
    deletePlayerFromSegment: 'Are you sure you want to delete the player from <0>{{name}}</0> segment?',
    deleteTagFromPlayerAccount: 'Are you sure you want to delete <0>{{name}}</0> tag from player account?',
    approveDocuments: 'Are you sure you want to approve selected documents?',
    updatePlayerVerification: "Are you sure you want to update player's verification?",
    deleteFromBlacklist: 'Are you sure you want to delete <0>{{name}}</0> persona from blacklist?',
    bulkDeleteFromBlacklist: 'Are you sure you want to delete selected personas from blacklist?',
    unTag: 'Are you sure you want to untag {{model}}?',
    unSavedChanges: 'Changes you made may not be saved. Do you want to save and continue?',
    move: 'Are you sure you want to move the {{model}}?',
    moveToDraft: 'Are you sure you want to move the message <0>{{name}}</0> to draft?',
    deletePlayerFromTheSegment: 'Are you sure you want to delete the <0>{{name}}</0> player from segment?',
    deleteGameFromSegment: 'Are you sure you want to delete the <0>{{name}}</0> game from segment?',
    deleteAllWhitelistedIpAddresses: 'Are you sure you want to delete all Whitelisted IP addresses?',
    deleteWhitelistedIpAddress: 'Are you sure you want to delete Whitelisted IP address?',
    deleteIpFromWhitelist: 'Are you sure you want to delete {{ip}} from your list?',
    changeView: 'Are you sure you want to change the <0>{{name}}</0> view?',
    cancelActiveBonuses: 'Are you sure you want to cancel all the active bonuses?',
    leavePage: 'Are you sure you want to leave the page?',
    removeFromCampaign: 'Are you sure you want to remove <0>{{name}}</0> bonus from campaign?',
    removeParticipantsFromCampaign: 'Are you sure you want to remove participants from campaign?',
    cancelPromotion: 'Are you sure you want to cancel  <0>{{name}}</0> campaign promotion?',
    makeAsDefault: 'Are you sure you want to make <0>{{name}}</0> as default?',
    resetAction: 'Are you sure you want to reset {{model}} for the <0>{{name}}</0>?',
    clean: 'Are you sure you want to clean <0>{{name}}</0> {{model}}?',
    sync: 'Are you sure you want to synchronize <0>{{name}}</0>',
    resendQRCode: 'Are you sure you want to resend the QR Code?',
    resendQRCodes: 'Are you sure you want to resend the QR Codes?',
    resetPrizeType: 'Are you sure you want to reset the type?',
    deleteLanguageContent: 'Are you sure you want to delete <0>{{type}}</0> <1>{{language}}</1> content ',
    resetSection: 'Are you sure you want to reset the section?',
    publish: 'Are you sure you want to publish <0>{{name}}</0> {{model}}?',
    resetTestPlayerPassword: 'Are you sure you want to reset the password for this account?',
    remove: 'Are you sure you want to remove <0>{{name}}</0>?',
    deletePromotionLanguageContent: 'Are you sure you want to delete <0>{{language}}</0> content ',
    logout: 'Are you sure you want to Log Out',
    removeContentFromCampaign: 'Are you sure you want to remove <0>{{name}}</0> bonus content and notification from the campaign?',
    resetIcon: 'Are you sure you want to reset <0>{{name}}</0> icon?',
    confirm: 'Are you sure you want to confirm the action?',
    deleteGameFromTypeGroup: 'Are you sure you want to remove <0>{{name}}</0> from the <0>{{type}}</0> type ?',
    deleteGamesFromTypeGroup: 'Are you sure you want to remove games from the <0>{{type}}</0> type ?',
    removePartnerBrandsMaintenance: 'Are you sure you want to remove Maintenance for <0>{{name}}</0> partner brands',
    removePartnersBrandsMaintenance: 'Are you sure you want to remove Maintenance for the selected partner brands',
    removeTriggerConditionsFromCampaign: 'Are you sure you want to remove trigger conditions from campaign?',
    setMainSportDomain: 'Are you sure you want to set main the <0>{{name}}</0> domain?',
    removeChangeFromReleaseNote: 'Are you sure you want to remove <0>{{name}}</0> from release note?',
  },
  es: {
    enableDisable: '¿Seguro que quiere {{action}} a <0>{{name}</0>?',
    enableDisableModel: '¿Seguro que quiere {{action}} el {{model}} <0>{{name}}</0>?',
    finish: '¿Seguro que quiere terminar el {{model}} <0>{{name}}</0>?',
    setMainCountry: '¿Seguro que quiere establecer {{action}} <0>{{name}}</0> como país de preferencia?',
    setMainCurrency: '¿Seguro que quiere establecer {{action}} <0>{{name}}</0> como moneda de preferencia?',
    setMainLanguage: '¿Seguro que quiere establecer {{action}} <0>{{name}}</0> como idioma de preferencia?',
    deleteRule: '¿Seguro que quiere eliminar la regla?',
    enableDisableSportBook: '¿Seguro que quiere habilitar al {{provider}} <0>{{name}}</0>?',
    delete: '¿Seguro que quiere eliminar el {{model}} <0>{{name}}</0>?',
    deleteByName: '¿Seguro que quiere eliminar el <0>{{name}}</0>?',
    deleteByModel: '¿Seguro que quiere eliminar el {{model}}?',
    enableGamesForAllBrands: '¿Seguro que quiere habilitar el/los juego(s) para todas las marcas?',
    enableCasinoGames: '¿Seguro que quiere {{action}} al proveedor <0>{{name}</0> en todas las marcas?',
    resetLargeTransactionValues: '¿Seguro que quiere restablecer los valores de <0>{{name}}</0>?',
    deleteScript: '¿Seguro que quiere eliminar el Script <0>{{name}}</0>?',
    resetSMS: '¿Seguro que quiere <0>restablecer</0> la configuración de los SMS?',
    makeEmailAsDefault: '¿Seguro que quiere que el correo electrónico <0>{{name}}</0> sea el predeterminado?',
    deleteCountry: '¿Seguro que quiere eliminar el país <0>{{name}}</0> de la lista de restricciones?',
    deleteBlockIp: '¿Seguro que quiere eliminar <0>{{name}}</0> de la lista de restricciones?',
    deleteVersion: '¿Seguro que quiere eliminar la versión?',
    cancel: '¿Seguro que quiere cancelar el {{model}} <0>{{name}}</0>?',
    disconnect: '¿Seguro que quiere desconectar el activador del {{model}} <0>{{name}}</0>?',
    emptyRule: '¿Seguro que quiere vaciar la regla <0>{{name}}</0>?',
    finishRule: '¿Seguro que quiere terminar la regla <0>{{name}}</0>?',
    sendCashback: '¿Seguro que quiere enviar la devolución en los siguientes períodos?',
    sendNoPeriodCashback: '¿Seguro que quiere enviar la devolución a los jugadores seleccionados?',
    reSendCashback: '¿Seguro que quiere volver a enviar la devolución en los siguientes períodos?',
    reSendNoPeriodCashback: '¿Seguro que quiere volver a enviar la devolución a los jugadores seleccionados?',
    deleteCashback: '¿Seguro que quiere volver a enviar la devolución en los siguientes períodos?',
    deletePlayerFromRestricted: '¿Seguro que quiere eliminar al jugador <0>{{name}}</0> de la lista de restricciones?',
    sendCommissions: '¿Seguro que quiere <0>enviar</0> comisiones?',
    reSendCommissions: '¿Seguro que quiere <0> volver a enviar</0> comisiones?',
    finishAccountUsage: '¿Seguro que quiere terminar el uso de la cuenta?',
    unlockPlayer: '¿Seguro que quiere desbloquear a este jugador?',
    blockPlayerForMessages: '¿Seguro que quiere {{action}} al jugador en los mensajes?',
    blacklistPlayer: '¿Seguro que quiere {{action}} al jugador?',
    activatePlayerAccount: '¿Seguro que quiere activar la cuenta del jugador?',
    activatePlayerStatus: '¿Seguro que quiere activar la cuenta del jugador {{action}}?',
    verifyPlayersAttribute: '¿Seguro que quiere {{action}} {{attribute}}del jugador?',
    completePhysicalVerification: '¿Seguro que quiere configurar la verificación física como {{action}}?',
    deletePlayerFromSegment: '¿Seguro que quiere eliminar al jugador del segmento <0>{{name}}</0>?',
    deleteTagFromPlayerAccount: '¿Seguro que quiere eliminar la etiqueta <0>{{name}}</0> de la cuenta del jugador?',
    approveDocuments: '¿Seguro que quiere aprobar los documentos seleccionados?',
    updatePlayerVerification: '¿Seguro que quiere actualizar la verificación del jugador?',
    deleteFromBlacklist: '¿Seguro que quiere eliminar al personaje <0>{{name}}</0> de la lista de no admitidos?',
    bulkDeleteFromBlacklist: '¿Seguro que quiere eliminar a los personajes seleccionados de la lista de no admitidos?',
    unTag: '¿Seguro que quiere desetiquetar el {{model}}?',
    unSavedChanges: 'Es posible que los cambios realizados no se guarden. ¿Desea guardar y continuar?',
    move: '¿Seguro que quiere mover el {{model}}?',
    moveToDraft: '¿Seguro que quiere mover el mensaje <0>{{name}}</0> a borradores?',
    deletePlayerFromTheSegment: '¿Seguro que quiere eliminar al jugador <0>{{name}}</0> del segmento?',
    deleteGameFromSegment: '¿Seguro que quiere eliminar el juego <0>{{name}}</0> del segmento?',
    deleteAllWhitelistedIpAddresses: '¿Seguro que quiere eliminar todas las direcciones IP de la lista de IP admitidas?',
    deleteWhitelistedIpAddress: '¿Seguro que quiere eliminar la dirección IP de la lista de IP admitidas?',
    deleteIpFromWhitelist: '¿Seguro que quiere eliminar {{ip}} de su lista?',
    changeView: '¿Está seguro de que quiere cambiar la vista <0>{{name}}</0>?',
    cancelActiveBonuses: '¿Estás seguro de que quieres cancelar todas las bonificaciones activas?',
    leavePage: '¿Está seguro de que quiere abandonar la página?',
    removeFromCampaign: '¿Estás seguro de que quieres eliminar la bonificación de <0>{{name}}</0> de la campaña?',
    removeParticipantsFromCampaign: '¿Está seguro de que quiere eliminar a los participantes de la campaña?',
    cancelPromotion: '¿Está seguro de que desea cancelar la promoción de la campaña <0>{{name}}</0>?',
    makeAsDefault: '¿Estás seguro de que quieres poner <0>{{name}}</0> por defecto?',
    resetAction: '¿Está seguro de que desea restablecer {{model}} para el <0>{{name}}</0>?',
    clean: '¿Estás seguro de que quieres limpiar <0>{{name}}</0> {{model}}?',
    sync: '¿Está seguro de que desea sincronizar <0>{{name}}</0>',
    resendQRCode: '¿Está seguro de que desea reenviar el código QR?',
    resendQRCodes: '¿Estás seguro de que quieres reenviar los códigos QR?',
    resetPrizeType: '¿Estás seguro de que quieres restablecer el tipo?',
    deleteLanguageContent: '¿Estás seguro de que quieres eliminar <0>{{type}}</0> <1>{{language}}</1> contenido?',
    resetSection: '¿Estás seguro de que quieres restablecer la sección?',
    publish: '¿Estás seguro de que quieres publicar <0>{{name}}</0> {{model}}?',
    resetTestPlayerPassword: '¿Estás seguro de que quieres restablecer la contraseña de esta cuenta?',
    remove: '¿Estás seguro de que quieres eliminar <0>{{name}}</0>?',
    deletePromotionLanguageContent: '¿Estás seguro de que quieres eliminar <0>{{language}}</0> contenido?',
    logout: '¿Estás seguro de que quieres cerrar sesión?',
    removeContentFromCampaign: '¿Estás seguro de que quieres eliminar el contenido del bono y la notificación de la campaña de <0>{{name}}</0>?',
    resetIcon: '¿Estás seguro de que quieres restablecer el icono de <0>{{name}}</0>?',
    confirm: '¿Estás seguro de que quieres confirmar la acción?',
  },
  tr: {
    enableDisable: '<0>{{name}}</0> {{action}} istediğinizden emin misiniz?',
    enableDisableModel: '<0>{{name}}</0> {{model}} {{action}} istediğinizden emin misiniz?',
    finish: '<0>{{name}}</0> {{model}} bitirmek istediğinizden emin misiniz?',
    setMainCountry: '{{action}} <0>{{name}}</0> konumunu ana ülke olarak belirlemek istediğinizden emin misiniz?',
    setMainCurrency: '{{action}} <0>{{name}}</0> para birimini ana para birimi olarak belirlemek istediğinizden emin misiniz?',
    setMainLanguage: '{{action}} <0>{{name}}</0> dilini ana dil olarak belirlemek istediğinizden emin misiniz?',
    deleteRule: 'Kuralı silmek istediğinizden emin misiniz?',
    enableDisableSportBook: '<0>{{name}}</0>{{provider}} etkinleştirilecek, emin misiniz?',
    delete: '<0>{{name}}</0> {{model}} silinecek, emin misiniz?',
    deleteByName: '<0>{{name}}</0> silinecek, emin misiniz?',
    deleteByModel: '{{model}} silinecek, emin misiniz?',
    enableGamesForAllBrands: 'Oyunun/Oyunların bütün markalar için etkinleştirilmesini istediğinizden emin misiniz?',
    enableCasinoGames: 'Sağlayıcıyı bütün markalar için {{action}} <0>{{name}}</0> istediğinizden emin misiniz?',
    resetLargeTransactionValues: '<0>{{name}}</0> için değerler sıfırlanacak, emin misiniz?',
    deleteScript: '<0>{{name}}</0> Komut Dizinin silinmesini istediğinizden emin misiniz?',
    resetSMS: 'SMS ayarlarını <0>sıfırlamak</0> istediğinizden emin misiniz?',
    makeEmailAsDefault: '<0>{{name}}</0> e-posta adresinizin varsayılan olarak ayarlanmasını istediğinizden emin misiniz?',
    deleteCountry: '<0>{{name}}</0> ülke kısıtlama listesinden silinecek, emin misiniz?',
    deleteBlockIp: '<0>{{name}}</0> ülke kısıtlama listesinden silinecek, emin misiniz?',
    deleteVersion: 'Sürümü silmek istediğinizden emin misiniz?',
    cancel: '<0>{{name}}</0> {{model}} iptal edilecek, emin misiniz?',
    disconnect: 'Tetikleyici ile <0>{{name}}</0> {{model}} bağlantısı kesilecek, emin misiniz?',
    emptyRule: '<0>{{name}}</0> kuralını boşaltmak istediğinizden emin misiniz?',
    finishRule: '<0>{{name}}</0> kuralını bitirmek istediğinizden emin misiniz?',
    sendCashback: 'Sonraki periyotlar için CashBack göndermek istediğinizden emin misiniz?',
    sendNoPeriodCashback: 'Seçili oyunculara CashBack göndermek istediğinizden emin misiniz?',
    reSendCashback: 'Sonraki periyotlar için tekrar CashBack göndermek istediğinizden emin misiniz?',
    reSendNoPeriodCashback: 'Seçili oyunculara tekrar CashBack göndermek istediğinizden emin misiniz?',
    deleteCashback: 'Sonraki periyotlar için CashBack’i silmek istediğinizden emin misiniz?',
    deletePlayerFromRestricted: '<0>{{name}}</0> adlı oyuncuyu kısıtlama listesinden silmek istediğinizden emin misiniz?',
    sendCommissions: 'Komisyonları <0>göndermek</0> istediğinizden emin misiniz?',
    reSendCommissions: 'Komisyonları <0>tekrar göndermek</0> istediğinizden emin misiniz?',
    finishAccountUsage: 'Hesap kullanımını bitirmek istediğinizden emin misiniz?',
    unlockPlayer: 'Bu oyuncunun kilidini açmak istediğinizden emin misiniz?',
    blockPlayerForMessages: 'Mesajlar için oyuncuya {{action}} eylemini uygulamak istediğinizden emin misiniz?',
    blacklistPlayer: 'Oyuncuya {{action}} eylemini uygulamak istediğinizden emin misiniz?',
    activatePlayerAccount: 'Oyuncunun hesabını etkinleştirmek istediğinizden emin misiniz?',
    activatePlayerStatus: 'Oyuncunun {{action} etkinleştirmek istediğinizden emin misiniz?',
    verifyPlayersAttribute: 'Oyuncunun {{attribute}} için {{action}} eylemini uygulamak istediğinizden emin misiniz?',
    completePhysicalVerification: 'Fiziksel doğrulamayı {{action}} olarak ayarlamak istediğinizden emin misiniz?',
    deletePlayerFromSegment: 'Oyuncuyu <0>{{name}}</0> bölümünden silmek istediğinizden emin misiniz?',
    deleteTagFromPlayerAccount: 'Oyuncu hesabından <0>{{name}}</0> etiketini silmek istediğinizden emin misiniz?',
    approveDocuments: 'Seçili belgeleri onaylamak istediğinizden emin misiniz?',
    updatePlayerVerification: 'Oyuncu doğrulamasını güncellemek istediğinizden emin misiniz?',
    deleteFromBlacklist: '<0>{{name}}</0> personayı kara listeden silmek istediğinizden emin misiniz?',
    bulkDeleteFromBlacklist: 'Seçili personaları kara listeden silmek istediğinizden emin misiniz?',
    unTag: '{{model}} etiketini kaldırmak istediğinizden emin misiniz?',
    unSavedChanges: 'Yaptığınız değişiklikler kaydedilmeyebilir. Kaydedip devam etmek istiyor musunuz?',
    move: "{{model}}'i taşımak istediğinizden emin misiniz?",
    moveToDraft: '<0>{{name}}</0> mesajını taslaklara taşımak istediğinizden emin misiniz?',
    deletePlayerFromTheSegment: '<0>{{name}}</0> adlı oyuncuyu bölümden silmek istediğinizden emin misiniz?',
    deleteGameFromSegment: '<0>{{name}}</0> adlı oyunu bölümden silmek istediğinizden emin misiniz?',
    deleteAllWhitelistedIpAddresses: 'Bütün Beyaz Listedeki IP adreslerini silmek istediğinizden emin misiniz?',
    deleteWhitelistedIpAddress: 'Beyaz Listedeki IP adresini silmek istediğinizden emin misiniz?',
    deleteIpFromWhitelist: 'Listenizden {{ip}} adresini silmek istediğinizden emin misiniz?',
    changeView: '<0>{{name}}</0> görünümünü değiştirmek istediğinizden emin misiniz?',
    cancelActiveBonuses: 'Tüm aktif bonusları iptal etmek istediğinizden emin misiniz?',
    leavePage: 'Sayfadan ayrılmak istediğinizden emin misiniz?',
    removeFromCampaign: '<0>{{name}}</0> bonusunu kampanyadan kaldırmak istediğinizden emin misiniz?',
    removeParticipantsFromCampaign: 'Katılımcıları kampanyadan çıkarmak istediğinizden emin misiniz?',
    cancelPromotion: '<0>{{name}}</0> kampanya promosyonunu iptal etmek istediğinizden emin misiniz?',
    makeAsDefault: 'Varsayılan olarak <0>{{name}}</0> yapmak istediğinizden emin misiniz?',
    resetAction: "<0>{{name}}</0> için {{model}}'i sıfırlamak istediğinizden emin misiniz?",
    clean: '<0>{{name}}</0> {{model}} temizlemek istediğinizden emin misiniz?',
    sync: '<0>{{name}}</0> senkronize etmek istediğinizden emin misiniz?',
    resendQRCode: 'QR Kodunu yeniden göndermek istediğinizden emin misiniz?',
    resendQRCodes: 'QR Kodlarını yeniden göndermek istediğinizden emin misiniz?',
    resetPrizeType: 'Türü sıfırlamak istediğinizden emin misiniz?',
    deleteLanguageContent: '<0>{{type}}</0> <1>{{language}}</1> içeriğini silmek istediğinizden emin misiniz? ',
    resetSection: 'Bölümü sıfırlamak istediğinizden emin misiniz?',
    publish: '<0>{{name}}</0> {{model}} yayınlamak istediğinizden emin misiniz?',
    resetTestPlayerPassword: 'Bu hesabın şifresini sıfırlamak istediğinizden emin misiniz?',
    remove: '<0>{{name}}</0> kaldırmak istediğinizden emin misiniz?',
    deletePromotionLanguageContent: '<0>{{language}}</0> içeriğini silmek istediğinizden emin misiniz? ',
    logout: 'Oturumu Kapatmak istediğinizden emin misiniz?',
    removeContentFromCampaign: '<0>{{name}}</0> bonus içeriğini ve bildirimini kampanyadan kaldırmak istediğinizden emin misiniz?',
    resetIcon: '<0>{{name}}</0> simgesini sıfırlamak istediğinizden emin misiniz?',
    confirm: 'İşlemi onaylamak istediğinizden emin misiniz?',
  },
  ru: {
    enableDisable: 'Вы действительно хотите {{action}} <0>{{name}}</0>?',
    enableDisableModel: 'Вы действительно хотите {{action}} <0>{{name}}</0> {{model}}?',
    finish: 'Вы действительно хотите закончить <0>{{name}}</0> {{model}}?',
    setMainCountry: 'Вы действительно хотите сделать {{action}} <0>{{name}}</0> основной страной?',
    setMainCurrency: 'Вы действительно хотите сделать {{action}} <0>{{name}}</0> основной валютой?',
    setMainLanguage: 'Вы действительно хотите сделать {{action}} <0>{{name}}</0> основным языком?',
    deleteRule: 'Вы действительно хотите удалить правило?',
    enableDisableSportBook: 'Вы действительно хотите сделать доступным <0>{{name}}</0>{{provider}}?',
    delete: 'Вы действительно хотите удалить <0>{{name}}</0> {{model}}?',
    deleteByName: 'Вы действительно хотите удалить <0>{{name}}</0>?',
    deleteByModel: 'Вы действительно хотите удалить {{model}}?',
    enableGamesForAllBrands: 'Вы действительно хотите сделать доступной(-ыми) игру(-ы) для всех брендов?',
    enableCasinoGames: 'Вы действительно хотите сделать {{action}} <0>{{name}}</0> провайдером для всех брендов?',
    resetLargeTransactionValues: 'Вы действительно хотите сбросить значения для <0>{{name}}</0>?',
    deleteScript: 'Вы действительно хотите удалить скрипт<0>{{name}}</0>?',
    resetSMS: 'Вы действительно хотите <0>сбросить</0> настройки SMS?',
    makeEmailAsDefault: 'Вы действительно хотите сделать электронную почту <0>{{имя}}</0> почтой по умолчанию?',
    deleteCountry: 'Вы действительно хотите удалить страну <0>{{имя}}</0> из списка ограничений?',
    deleteBlockIp: 'Вы действительно хотите удалить <0>{{name}}</0> из списка ограничений?',
    deleteVersion: 'Вы действительно хотите удалить эту версию?',
    cancel: 'Вы действительно хотите отменить <0>{{name}}</0> {{model}}?',
    disconnect: 'Вы действительно хотите отключить триггер от <0>{{name}}</0> {{model}}?',
    emptyRule: 'Вы действительно хотите сделать пустым правило <0>{{name}}</0>?',
    finishRule: 'Вы действительно хотите завершить правило <0>{{name}}</0>?',
    sendCashback: 'Вы действительно хотите отправить Кэшбэк за следующие периоды?',
    sendNoPeriodCashback: 'Вы действительно хотите отправить Кэшбэк выбранным игрокам?',
    reSendCashback: 'Вы действительно хотите повторно отправить Кэшбэк за следующие периоды?',
    reSendNoPeriodCashback: 'Вы действительно хотите повторно отправить Кэшбэк выбранным игрокам?',
    deleteCashback: 'Вы действительно хотите удалить Кэшбэк за следующие периоды?',
    deletePlayerFromRestricted: 'Вы действительно хотите удалить игрока <0>{{name}}</0> из списка ограничений?',
    sendCommissions: 'Вы действительно хотите <0>send</0> комиссии?',
    reSendCommissions: 'Вы действительно хотите <0>resend</0> комиссии?',
    finishAccountUsage: 'Вы действительно хотите прекратить использование учетной записи?',
    unlockPlayer: 'Вы действительно хотите разблокировать этого игрока?',
    blockPlayerForMessages: 'Вы действительно хотите {{action}} игрока для сообщений?',
    blacklistPlayer: 'Вы действительно хотите {{action}} этого игрока?',
    activatePlayerAccount: 'Вы действительно хотите активировать учетную запись игрока?',
    activatePlayerStatus: 'Вы действительно хотите активировать {{action}} игрока?',
    verifyPlayersAttribute: 'Вы действительно хотите {{action}} {{attribute}} игрока?',
    completePhysicalVerification: 'Вы действительно хотите установить физическую верификацию в качестве {{action}}?',
    deletePlayerFromSegment: 'Вы действительно хотите удалить игрока из сегмента <0>{{name}}</0>?',
    deleteTagFromPlayerAccount: 'Вы действительно хотите удалить тег <0>{{name}}</0> с учетной записи игрока?',
    approveDocuments: 'Вы действительно хотите одобрить выбранные документы?',
    updatePlayerVerification: 'Вы действительно хотите обновить верификацию игрока?',
    deleteFromBlacklist: 'Вы действительно хотите удалить пользователя <0>{{name}}</0> из списка запрещенных?',
    bulkDeleteFromBlacklist: 'Вы действительно хотите удалить выбранных пользователей из списка запрещенных?',
    unTag: 'Вы действительно хотите удалить тег с {{model}}?',
    unSavedChanges: 'Возможно, внесенные изменения не сохранены. Сохранить и продолжить?',
    move: 'Вы действительно хотите переместить {{model}}?',
    moveToDraft: 'Вы действительно хотите переместить сообщение <0>{{name}}</0> в черновики?',
    deletePlayerFromTheSegment: 'Вы действительно хотите удалить игрока <0>{{name}}</0> из сегмента?',
    deleteGameFromSegment: 'Вы действительно хотите удалить игру <0>{{name}}</0> из сегмента?',
    deleteAllWhitelistedIpAddresses: 'Вы действительно хотите удалить все IP-адреса из списка разрешенных?',
    deleteWhitelistedIpAddress: 'Вы действительно хотите удалить IP-aдрес из списка разрешенных?',
    deleteIpFromWhitelist: 'Вы действительно хотите удалить {{ip}} из вашего списка?',
    changeView: 'Вы действительно хотите изменить вид <0>{{name}}</0>?',
    cancelActiveBonuses: 'Вы действительно хотите отменить все активные бонусы?',
    leavePage: 'Вы действительно хотите покинуть страницу?',
    removeFromCampaign: 'Вы действительно хотите удалить <0>{{name}}</0> бонус из кампании?',
    removeParticipantsFromCampaign: 'Вы действительно хотите удалить участников из кампании?',
    cancelPromotion: 'Вы действительно хотите отменить  <0>{{name}}</0> кампанию акции?',
    makeAsDefault: 'Вы действительно хотите использовать <0>{{name}}</0> по умолчанию?',
    resetAction: 'Вы действительно хотите сбросить {{model}} для <0>{{name}}</0>?',
    clean: 'Вы действительно хотите очистить <0>{{name}}</0> {{model}}?',
    sync: 'Вы действительно хотите синхронизировать <0>{{name}}</0>?',
    resendQRCode: 'Вы действительно хотите повторно отправить QR-код?',
    resendQRCodes: 'Вы действительно хотите повторно отправить QR-коды?',
    resetPrizeType: 'Вы действительно хотите сбросить тип?',
    deleteLanguageContent: 'Вы действительно хотите удалить <0>{{type}}</0> <1>{{language}}</1> контент?',
    resetSection: 'Вы действительно хотите сбросить раздел?',
    publish: 'Вы действительно хотите опубликовать <0>{{name}}</0> {{model}}?',
    resetTestPlayerPassword: 'Вы действительно хотите сбросить пароль для этого аккаунта?',
    remove: 'Вы действительно хотите удалить <0>{{name}}</0>?',
    deletePromotionLanguageContent: 'Вы действительно хотите удалить <0>{{language}}</0> контент?',
    logout: 'Вы действительно хотите выйти из системы?',
    removeContentFromCampaign: 'Вы действительно хотите удалить <0>{{name}}</0> бонусный контент и уведомление из кампании?',
    resetIcon: 'Вы действительно хотите сбросить <0>{{name}}</0> иконку?',
    confirm: 'Вы действительно хотите подтвердить действие?',
  },
  pt: {
    enableDisable: 'Tem a certeza de que pretende {{action}} <0>{{name}}</0>?',
    enableDisableModel: 'Tem a certeza de que pretende {{action}} <0>{{name}}</0> {{model}}?',
    finish: 'Tem a certeza de que pretende finalizar <0>{{name}}</0> {{model}}?',
    setMainCountry: 'Tem a certeza de que pretende definir {{action}} <0>{{name}}</0> como país principal?',
    setMainCurrency: 'Tem a certeza de que pretende definir {{action}} <0>{{name}}</0> como moeda principal?',
    setMainLanguage: 'Tem a certeza de que pretende definir {{action}} <0>{{name}}</0> como idioma principal?',
    deleteRule: 'Tem a certeza de que pretende apagar a regra?',
    enableDisableSportBook: 'Tem a certeza de que pretende habilitar <0>{{name}}</0>{{provider}}?',
    delete: 'Tem a certeza de que pretende excluir o <0>{{name}}</0> {{model}}?',
    deleteByName: 'Tem a certeza de que pretende excluir o <0>{{name}}</0>?',
    deleteByModel: 'Tem a certeza de que pretende excluir o {{model}}?',
    enableGamesForAllBrands: 'Tem a certeza de que pretende habilitar o(s) jogo(s) para todas as marcas?',
    enableCasinoGames: 'Tem a certeza de que pretende {{action}} <0>{{name}}</0> fornecedor para todas as marcas?',
    resetLargeTransactionValues: 'Tem a certeza de que pretende redefinir os valores para <0>{{name}}</0>?',
    deleteScript: 'Tem a certeza de que pretende apagar o script <0>{{name}}</0>?',
    resetSMS: 'Tem a certeza de que pretende <0>reinicializar</0> as configurações de SMS?',
    makeEmailAsDefault: 'Tem a certeza de que pretende definir <0>{{name}}</0> como e-mail padrão?',
    deleteCountry: 'Tem a certeza de que pretende excluir <0>{{name}}</0> da lista de países restritos?',
    deleteBlockIp: 'Tem a certeza de que pretende excluir <0>{{name}}</0> da lista de restrições?',
    deleteVersion: 'Tem a certeza de que pretende apagar a versão?',
    cancel: 'Tem a certeza de que pretende cancelar <0>{{name}}</0> {{model}}?',
    disconnect: 'Tem a certeza de que pretende desconectar o ativador de <0>{{name}}</0> {{model}}?',
    emptyRule: 'Tem a certeza de que pretende esvaziar a regra <0>{{name}}</0>?',
    finishRule: 'Tem a certeza de que pretende finalizar a regra <0>{{name}}</0>?',
    sendCashback: 'Tem a certeza de que pretende enviar o CashBack para os seguintes períodos?',
    sendNoPeriodCashback: 'Tem a certeza de que pretende enviar o CashBack aos jogadores selecionados?',
    reSendCashback: 'Tem a certeza de que pretende reenviar o CashBack para os seguintes períodos?',
    reSendNoPeriodCashback: 'Tem a certeza de que pretende reenviar o CashBack aos jogadores selecionados?',
    deleteCashback: 'Tem a certeza de que deseja apagar o CashBack nos seguintes períodos?',
    deletePlayerFromRestricted: 'Tem a certeza de que pretende excluir o jogador <0>{{name}}</0> da lista de restrições?',
    sendCommissions: 'Tem a certeza de que pretende <0>enviar</0> comissões?',
    reSendCommissions: 'Tem a certeza de que pretende <0>reenviar</0> comissões?',
    finishAccountUsage: 'Tem a certeza de que pretende terminar a utilização da conta?',
    unlockPlayer: 'Tem a certeza de que pretende desbloquear este jogador?',
    blockPlayerForMessages: 'Tem a certeza de que pretende {{action}} o jogador para mensagens?',
    blacklistPlayer: 'Tem a certeza de que pretende {{action}} o jogador?',
    activatePlayerAccount: 'Tem a certeza de que pretende ativar a conta do jogador?',
    activatePlayerStatus: 'Tem a certeza de que pretende ativar {{action}} do jogador?',
    verifyPlayersAttribute: 'Tem a certeza de que pretende {{action}} o {{attribute}} do jogador?',
    completePhysicalVerification: 'Tem a certeza de que pretende definir a verificação física como {{action}}?',
    deletePlayerFromSegment: 'Tem a certeza de que deseja excluir o jogador do segmento <0>{{name}}</0>?',
    deleteTagFromPlayerAccount: 'Tem a certeza de que deseja excluir a tag <0>{{name}}</0> da conta do jogador?',
    approveDocuments: 'Tem a certeza de que pretende aprovar os documentos selecionados?',
    updatePlayerVerification: 'Tem a certeza de que pretende atualizar a verificação do jogador?',
    deleteFromBlacklist: 'Tem a certeza de que pretende excluir a persona <0>{{name}}</0> da lista de proibições?',
    bulkDeleteFromBlacklist: 'Tem a certeza de que deseja excluir pessoas selecionadas da lista de proibições?',
    unTag: 'Tem a certeza de que pretende untag {{model}}?',
    unSavedChanges: 'As alterações que fez não podem ser gravadas. Pretende gravar e continuar?',
    move: 'Tem a certeza de que pretende mudar o modelo {{model}}?',
    moveToDraft: 'Tem a certeza de que pretende mover a mensagem <0>{{name}}</0> para o rascunho?',
    deletePlayerFromTheSegment: 'Tem a certeza de que pretende excluir o jogador <0>{{name}}</0> do segmento?',
    deleteGameFromSegment: 'Tem a certeza de que pretende excluir o jogo <0>{{name}}</0> do segmento?',
    deleteAllWhitelistedIpAddresses: 'Tem a certeza de que pretende excluir todos os endereços IP da lista de permissões?',
    deleteWhitelistedIpAddress: 'Tem a certeza de que deseja excluir o endereço IP da lista de permissões?',
    deleteIpFromWhitelist: 'Tem a certeza de que deseja excluir {{ip}} da sua lista?',
    changeView: 'Tem a certeza de que pretende alterar a vista <0>{{name}}</0>?',
    cancelActiveBonuses: 'Tem a certeza de que pretende cancelar todos os bónus ativos?',
    leavePage: 'Tem a certeza de que pretende deixar a página?',
    removeFromCampaign: 'Tem a certeza de que pretende remover o bónus <0>{{name}}</0> da campanha?',
    removeParticipantsFromCampaign: 'Tem a certeza de que pretende remover participantes da campanha?',
    cancelPromotion: 'Tem a certeza de que pretende cancelar a promoção da campanha <0>{{name}}</0>?',
    makeAsDefault: 'Tem a certeza de que pretende tornar <0>{{name}}</0> como padrão?',
    resetAction: 'Tem certeza de que deseja redefinir {{model}} para <0>{{name}}</0>?',
    clean: 'Tem certeza de que deseja limpar <0>{{name}}</0> {{model}}?',
    sync: 'Tem certeza de que deseja sincronizar <0>{{name}}</0>',
    resendQRCode: 'Tem certeza que deseja reenviar o QR Code?',
    resendQRCodes: 'Tem certeza que deseja reenviar os QR codes?',
    resetPrizeType: 'Tem a certeza de que pretende redefinir o tipo?',
    deleteLanguageContent: 'Tem a certeza de que pretende eliminar o conteúdo de <0>{{type}}</0> <1>{{{language}}</1> ',
    resetSection: 'Tem a certeza de que pretende redefinir a secção?',
    publish: 'Tem a certeza de que pretende publicar <0>{{nome}}</0> {{modelo}}?',
    resetTestPlayerPassword: 'Tem a certeza de que pretende redefinir a palavra-passe desta conta?',
    remove: 'Tem a certeza de que pretende remover <0>{{nome}}</0>?',
    deletePromotionLanguageContent: 'Tem a certeza de que pretende eliminar o conteúdo de <0>{{language}}</0>? ',
    logout: 'Tem a certeza de que pretende terminar a sessão',
    removeContentFromCampaign: 'Tem a certeza de que pretende remover <0>{{nome}}</0> conteúdos de bónus e notificações da campanha?',
    resetIcon: 'Tem a certeza de que pretende repor o ícone <0>{{name}}</0>?',
    confirm: 'Tem a certeza de que pretende confirmar a ação?',
  },
  zh: {
    enableDisable: '您确定要 {{action}} <0>{{name}}</0>？',
    enableDisableModel: '您确定要 {{action}} <0>{{name}}</0> {{model}}？',
    finish: '您确定要结束<0>{{name}}</0> {{model}}？',
    setMainCountry: '您确定要将 {{action}} <0>{{name}}</0>设置为主要国家？',
    setMainCurrency: '您确定要将 {{action}} <0>{{name}}</0>设置为主要货币？',
    setMainLanguage: '您确定要将 {{action}} <0>{{name}}</0>设置为主要语言？',
    deleteRule: '您确定要删除该规则？',
    enableDisableSportBook: '您确定要启用<0>{{name}}</0>{{provider}}？',
    delete: '您确定要删除<0>{{name}}</0> {{model}}？',
    deleteByName: '您确定要删除<0>{{name}}</0>？',
    deleteByModel: '您确定要删除 {{model}}？',
    enableGamesForAllBrands: '您确定要为所有品牌启用游戏？',
    enableCasinoGames: '您确定要 {{action}} <0>{{name}}</0>所有品牌的供应商供应商？',
    resetLargeTransactionValues: '您是否确定要为<0>{{name}}</0>重设值？',
    deleteScript: '您是否确定删除名为<0>{{name}}</0>的脚本？',
    resetSMS: '您是否确定要<0>重置</0>短信设置？',
    makeEmailAsDefault: '您是否确定要把<0>{{name}}</0>设置为您的默认电子邮箱？',
    deleteCountry: '您是否确定将名为<0>{{name}}</0>的国家从限制列表中删除？',
    deleteBlockIp: '您确定要从限制列表中删除<0>{{name}}</0>吗？',
    deleteVersion: '您是否确定删除该版本？',
    cancel: '您是否确定取消<0>{{name}}</0> {{model}}？',
    disconnect: '您是否确定要从<0>{{name}}</0> {{model}}中断开触发器？',
    emptyRule: '您是否确定要清空<0>{{name}}</0>规则？',
    finishRule: '您确定要完成<0>{{name}}</0>规则吗？',
    sendCashback: '您是否确定要在以下时段重新发送现金返还？',
    sendNoPeriodCashback: '您确定要向选定的玩家发送返现吗？',
    reSendCashback: '您确定要在以下时段重新发送现金返还？',
    reSendNoPeriodCashback: '您确定要重新发送现金返还至选中的玩家？',
    deleteCashback: '您确定要在以下时段删除现金返还功能？',
    deletePlayerFromRestricted: '您确定要将<0>{{name}}</0>玩家从限制列表中删除？',
    sendCommissions: '您确定要<0>发送</0>佣金？',
    reSendCommissions: '您确定要<0>重新发送</0>佣金？',
    finishAccountUsage: '您确定要结束该帐户的使用？',
    unlockPlayer: '您确定要解锁该玩家？',
    blockPlayerForMessages: '您确定要 {{action}} 来为玩家提供信息？',
    blacklistPlayer: '您确定要 {{action}} 该玩家？',
    activatePlayerAccount: '您确定要激活玩家帐户？',
    activatePlayerStatus: '您确定要激活玩家 {{action}}？',
    verifyPlayersAttribute: '您确定要对玩家的 {{attribute}} 进行 {{action}}？',
    completePhysicalVerification: '您确定要将物理验证设置为 {{action}}？',
    deletePlayerFromSegment: '您确定从<0>{{name}}</0>分区中删除该玩家？',
    deleteTagFromPlayerAccount: '您确定将<0>{{name}}</0>标记从玩家帐户中删除？',
    approveDocuments: '您确定要批准已选中的文件？',
    updatePlayerVerification: '您确定要更新玩家验证？',
    deleteFromBlacklist: '您确定要从黑名单中删除 <0>{name}}</0> 角色吗？',
    bulkDeleteFromBlacklist: '您确定要从黑名单中删除所选角色？',
    unTag: '您确定要为 {{model}} 取消标记？',
    unSavedChanges: '您所做的修改可能不会被保存。您想保存并继续吗？',
    move: '您确定要移动{{model}}？',
    moveToDraft: '您确定要将<0>{{name}}</0>信息移至草稿？',
    deletePlayerFromTheSegment: '您确定从分区中删除<0>{{name}}</0>玩家？',
    deleteGameFromSegment: '您确定从分区中删除<0>{{name}}</0>游戏？',
    deleteAllWhitelistedIpAddresses: '您确定要删除所有在白名单中的IP地址？',
    deleteWhitelistedIpAddress: '您确定要删除在白名单中的IP地址？',
    deleteIpFromWhitelist: '您确定要将 {{ip}} 从您的列表中删除？',
    changeView: '您确定要更改<0>{{name}}</0>视图？',
    cancelActiveBonuses: '您确定要取消所有活跃奖金？',
    leavePage: '您确定您要离开这个页面吗？',
    removeFromCampaign: '您确定要从活动中删除<0>{{name}}</0>奖金吗？',
    removeParticipantsFromCampaign: '您确定要从活动中删除参与者吗？',
    cancelPromotion: '您确定要取消<0>{{name}}</0>活动推广吗？',
    makeAsDefault: '您确定要把<0>{{name}}</0>作为默认值吗？',
    resetAction: '您确定要为<0>{{name}}</0>重置{{model}}吗？',
    clean: '您确定要清理<0>{{name}}</0>{{model}}吗？',
    sync: '您确定您要同步<0>{{name}}</0>吗？',
    resendQRCode: '您确定要重新发送QR码吗？',
    resendQRCodes: '您确定要重新发送QR码吗？',
    resetPrizeType: '您确定要重设类型吗？',
    deleteLanguageContent: '您确定要删除 <0>{{type}}</0> <1>{{language}}</1> 内容吗？',
    resetSection: '您确定要重新设置分区吗？',
    publish: '您确定要发布 <0>{{name}}</0> {{model}}?',
    resetTestPlayerPassword: '您确定要重置此账户的密码吗？',
    remove: '您确定要移除 <0>{{name}}</0>?',
    deletePromotionLanguageContent: '您确定要删除 <0>{{language}}</0> 内容 ',
    logout: '您确定要退出登录',
    removeContentFromCampaign: '您确定要删除活动中的 <0>{{name}}</0> 红利内容和通知吗?',
    resetIcon: '您确定要重置 <0>{{name}}</0> 图标?',
    confirm: '您确定要确认操作吗？',
  },
  ro: {
    enableDisable: 'Ești sigur că dorești să {{action}} <0>{{name}}</0>?',
    enableDisableModel: 'Sigur dorești să {{action}} <0>{{name}}</0> {{model}}?',
    finish: 'Sigur dorești să termini {{action}} <0>{{name}}</0> {{model}}?',
    setMainCountry: 'Sigur dorești să setezi {{action}} <0>{{name}}</0> ca țară principală?',
    setMainCurrency: 'Sigur dorești să setezi {{action}} <0>{{name}}</0> ca monedă principală?',
    setMainLanguage: 'Sigur dorești să setezi {{action}} <0>{{name}}</0> ca limbă principală?',
    deleteRule: 'Ești sigur că dorești să ștergi regula?',
    enableDisableSportBook: 'Sigur dorești să activezi <0>{{name}}</0>{{provider}}?',
    delete: 'Sigur dorești să ștergi <0>{{name}}</0> {{model}}?',
    deleteByName: 'Sigur dorești să ștergi <0>{{name}}</0>?',
    deleteByModel: 'Sigur dorești să ștergi {{modelul}}?',
    enableGamesForAllBrands: 'Sigur dorești să activezi jocul(jocurile) pentru toate mărcile (plățile de plată)?',
    enableCasinoGames: 'Sigur dorești să {{action}} <0>{{name}}</0> furnizor pentru toate mărcile?',
    resetLargeTransactionValues: 'Sigur dorești să resetezi valorile pentru <0>{{name}}</0>?',
    deleteScript: 'Ești sigur că dorești să ștergi Scriptul <0>{{name}}</0>?',
    resetSMS: 'Sigur dorești să <0>resetezi</0> setările de SMS-uri?',
    makeEmailAsDefault: 'Sigur dorești să setezi e-mailul <0>{{name}}</0> ca implicit?',
    deleteCountry: 'Sigur dorești să ștergi țara <0>{{name}}</0> din lista de restricții?',
    deleteBlockIp: 'Sigur dorești să ștergi <0>{{name}}</0> din lista de restricții?',
    deleteVersion: 'Ești sigur că dorești să ștergi versiunea?',
    cancel: 'Sigur dorești să anulezi <0>{{name}}</0> {{model}}?',
    disconnect: 'Sigur dorești să deconectezi declanșatorul de la <0>{{name}}</0> {{model}}?',
    emptyRule: 'Sigur dorești să golești regula <0>{{name}}</0>?',
    finishRule: 'Sigur dorești să finalizezi regula <0>{{name}}</0>?',
    sendCashback: 'Sigur dorești să trimiți CashBack pentru următoarele perioade?',
    sendNoPeriodCashback: 'Sigur dorești să trimiți CashBack jucătorilor selectați?',
    reSendCashback: 'Sigur dorești să retrimiți CashBack pentru următoarele perioade?',
    reSendNoPeriodCashback: 'Sigur dorești să retrimiți CashBack jucătorilor selectați?',
    deleteCashback: 'Sigur dorești să ștergi CashBack-ul pentru următoarele perioade?',
    deletePlayerFromRestricted: 'Sigur dorești să ștergi jucătorul <0>{{name}}</0> din lista de restricții?',
    sendCommissions: 'Sigur dorești să <0>trimiți</0> comisioane?',
    reSendCommissions: 'Sigur dorești să <0>retrimiți</0> comisioanele?',
    finishAccountUsage: 'Sigur dorești să termii utilizarea contului?',
    unlockPlayer: 'Sigur dorești să deblochezi acest jucător?',
    blockPlayerForMessages: 'Sigur dorești să {{action}} jucătorul pentru mesaje?',
    blacklistPlayer: 'Sigur dorești să {{acționați}} jucătorul?',
    activatePlayerAccount: 'Sigur dorești să activezi contul jucătorului?',
    activatePlayerStatus: 'Sigur dorești să activezi jucătorul {{action}}?',
    verifyPlayersAttribute: 'Sigur dorești să {{action}} jucătorului {{attribute}}?',
    completePhysicalVerification: 'Sigur dorești să setezi verificarea fizică ca {{action}}?',
    deletePlayerFromSegment: 'Sigur dorești să ștergi jucătorul din segmentul <0>{{name}}</0>?',
    deleteTagFromPlayerAccount: 'Sigur dorești să ștergi eticheta <0>{{name}}</0> din contul jucătorului?',
    approveDocuments: 'Sigur dorești să aprobezi documentele selectate?',
    updatePlayerVerification: 'Sigur dorești să actualizezi verificarea jucătorului?',
    deleteFromBlacklist: 'Sigur dorești să ștergi persona <0>{{name}}</0>  de pe/din lista neagră?',
    bulkDeleteFromBlacklist: 'Sigur dorești să ștergi persoanele selectate din lista neagră?',
    unTag: 'Sigur dorești să deetichetezi {{model}}?',
    unSavedChanges: 'Este posibil ca schimbările efectuate de tine să nu fie salvate. Dorești să le salvezi și să continui?',
    move: 'Sigur dorești să muți {{modelul}}?',
    moveToDraft: 'Sigur dorești să muți mesajul <0>{{name}}</0> în schiță?',
    deletePlayerFromTheSegment: 'Sigur dorești să ștergi jucătorul <0>{{name}}</0> din segment?',
    deleteGameFromSegment: 'Sigur dorești să ștergi jocul <0>{{name}}</0> din segment?',
    deleteAllWhitelistedIpAddresses: 'Sigur dorești să ștergi toate adresele IP din lista albă?',
    deleteWhitelistedIpAddress: 'Sigur dorești să ștergi adresa IP din lista albă?',
    deleteIpFromWhitelist: 'Sigur dorești să ștergi {{ip}} de pe listă?',
    changeView: 'Sigur dorești să schimbi vizualizarea <0>{{name}}</0>?',
    cancelActiveBonuses: 'Sigur dorești să anulezi toate bonusurile active?',
    leavePage: 'Sigur dorești să părăsești pagina?',
    removeFromCampaign: 'Ești sigur că dorești să eliminezi bonusul <0>{{{name}}</0> din campanie?',
    removeParticipantsFromCampaign: 'Ești sigur că dorești să eliminezi participanții din campanie?',
    cancelPromotion: 'Ești sigur că dorești să anulezi <0>{{{name}}</0> din promoția campaniei?',
    makeAsDefault: 'Ești sigur că dorești să setezi <0>{{{name}}</0> ca implicit?',
    resetAction: 'Ești sigur că dorești să resetezi {{model}} pentru <0>{{{name}}</0>?',
    clean: 'Ești sigur că dorești să ștergi {{model}} pentru <0>{{{name}}</0>?',
    sync: 'Ești sigur că dorești să sincronizezi <0>{{name}}</0>?',
    resendQRCode: 'Ești sigur că dorești să retrimiți codul QR?',
    resendQRCodes: 'Ești sigur că dorești să retrimiți codurile QR?',
  },
  bg: {},
  el: {
    enableDisable: 'Είστε σίγουροι ότι θέλετε {{action}} για <0>{{name}}</0>;',
    enableDisableModel: 'Είστε σίγουροι ότι θέλετε {{action}} για <0>{{name}}</0> {{model}};',
    finish: 'Είστε σίγουροι ότι θέλετε να τερματίσετε το <0>{{name}}</0> {{model}};',
    setMainCountry: 'Είστε σίγουροι ότι θέλετε να ορίσετε {{action}} <0>{{name}}</0> ως την κύρια χώρα;',
    setMainCurrency: 'Είστε σίγουροι ότι θέλετε να ορίσετε {{action}} <0>{{name}}</0> ως το κύριο νόμισμα;',
    setMainLanguage: 'Είστε σίγουροι ότι θέλετε να ορίσετε {{action}} <0>{{name}}</0> ως την κύρια γλώσσα;',
    deleteRule: 'Είστε σίγουροι ότι θέλετε να διαγράψετε τον κανόνα;',
    enableDisableSportBook: 'Είστε σίγουροι ότι θέλετε να ενεργοποιήσετε τον πάροχο <0>{{name}}</0>{{provider}};',
    delete: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το <0>{{name}}</0> {{model}};',
    deleteByName: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το <0>{{name}}</0>;',
    deleteByModel: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το {{model}};',
    enableGamesForAllBrands: 'Είστε σίγουροι ότι θέλετε να ενεργοποιήσετε παιχνίδι(-α) για όλες τις επωνυμίες;',
    enableCasinoGames: 'Είστε σίγουροι ότι θέλετε {{action}} για τον πάροχο <0>{{name}}</0> για όλες τις επωνυμίες;',
    resetLargeTransactionValues: 'Είστε σίγουροι ότι θέλετε να επαναφέρετε τις τιμές για <0>{{name}}</0>;',
    deleteScript: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το Σενάριο <0>{{name}}</0>;',
    resetSMS: 'Είστε σίγουροι ότι θέλετε να <0>επαναφέρετε</0> τις ρυθμίσεις SMS;',
    makeEmailAsDefault: 'Είστε σίγουροι ότι θέλετε να ορίσετε το email <0>{{name}}</0> ως προεπιλογή;',
    deleteCountry: 'Είστε σίγουροι ότι θέλετε να διαγράψετε τη χώρα <0>{{name}}</0> από τη λίστα περιορισμών;',
    deleteBlockIp: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το/τη <0>{{name}}</0> από τη λίστα περιορισμών;',
    deleteVersion: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή την έκδοση;',
    cancel: 'Είστε σίγουροι ότι θέλετε να ακυρώσετε το <0>{{name}}</0> {{model}};',
    disconnect: 'Είστε σίγουροι ότι θέλετε να αποσυνδέσετε την ενεργοποίηση από το <0>{{name}}</0> {{model}};',
    emptyRule: 'Είστε σίγουροι ότι θέλετε να αδειάσετε τον κανόνα <0>{{name}}</0>;',
    finishRule: 'Είστε σίγουροι ότι θέλετε να τερματίσετε τον κανόνα <0>{{name}}</0>;',
    sendCashback: 'Είστε σίγουροι ότι θέλετε να στείλετε Επιστροφή μετρητών για τις παρακάτω περιόδους;',
    sendNoPeriodCashback: 'Είστε σίγουροι ότι θέλετε να στείλετε Επιστροφή μετρητών στους επιλεγμένους παίκτες;',
    reSendCashback: 'Είστε σίγουροι ότι θέλετε να στείλετε εκ νέου Επιστροφή μετρητών για τις παρακάτω περιόδους;',
    reSendNoPeriodCashback: 'Είστε σίγουροι ότι θέλετε να στείλετε εκ νέου Επιστροφή μετρητών στους επιλεγμένους παίκτες;',
    deleteCashback: 'Είστε σίγουροι ότι θέλετε να διαγράψετε την Επιστροφή μετρητών για τις παρακάτω περιόδους;',
    deletePlayerFromRestricted: 'Είστε σίγουροι ότι θέλετε να διαγράψετε τον παίκτη <0>{{name}}</0> από τη λίστα περιορισμών;',
    sendCommissions: 'Είστε σίγουροι ότι θέλετε να <0>στείλετε</0> προμήθειες;',
    reSendCommissions: 'Είστε σίγουροι ότι θέλετε να <0>στείλετε ξανά</0> προμήθειες;',
    finishAccountUsage: 'Είστε σίγουροι ότι θέλετε να τερματίσετε τη χρήση του λογαριασμού;',
    unlockPlayer: 'Είστε σίγουροι ότι θέλετε να ξεκλειδώσετε αυτόν τον παίκτη;',
    blockPlayerForMessages: 'Είστε σίγουροι ότι θέλετε {{action}} για τον παίκτη για μηνύματα;',
    blacklistPlayer: 'Είστε σίγουροι ότι θέλετε {{action}} για τον παίκτη;',
    activatePlayerAccount: 'Είστε σίγουροι ότι θέλετε να ενεργοποιήσετε τον λογαριασμό παίκτη;',
    activatePlayerStatus: 'Είστε σίγουροι ότι θέλετε να ενεργοποιήσετε {{action}} για τον παίκτη;',
    verifyPlayersAttribute: 'Είστε σίγουροι ότι θέλετε {{action}} για {{attribute}} του παίκτη;',
    completePhysicalVerification: 'Είστε σίγουροι ότι θέλετε να ορίσετε φυσική επαλήθευση ως {{action}};',
    deletePlayerFromSegment: 'Είστε σίγουροι ότι θέλετε να διαγράψετε τον παίκτη από το τμήμα <0>{{name}}</0>;',
    deleteTagFromPlayerAccount: 'Είστε σίγουροι ότι θέλετε να διαγράψετε την ετικέτα <0>{{name}}</0> από τον λογαριασμό παίκτη;',
    approveDocuments: 'Είστε σίγουροι ότι θέλετε να εγκρίνετε τα επιλεγμένα έγγραφα;',
    updatePlayerVerification: 'Είστε σίγουροι ότι θέλετε να ενημερώσετε την επαλήθευση παίκτη;',
    deleteFromBlacklist: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το άτομο <0>{{name}}</0> από τη λίστα αποκλεισμένων;',
    bulkDeleteFromBlacklist: 'Είστε σίγουροι ότι θέλετε διαγράψετε τα επιλεγμένα άτομα από τη λίστα αποκλεισμένων;',
    unTag: 'Είστε σίγουροι ότι θέλετε να αφαιρέσετε την ετικέτα για {{model}};',
    unSavedChanges: 'Οι αλλαγές που κάνατε ενδέχεται να μην έχουν αποθηκευτεί. Θέλετε να κάνετε αποθήκευση και να συνεχίσετε;',
    move: 'Είστε σίγουροι ότι θέλετε να μετακινήσετε το {{model}};',
    moveToDraft: 'Είστε σίγουροι ότι θέλετε να μετακινήσετε το μήνυμα <0>{{name}}</0> στο πρόχειρο;',
    deletePlayerFromTheSegment: 'Είστε σίγουροι ότι θέλετε να διαγράψετε τον παίκτη <0>{{name}}</0> από το τμήμα;',
    deleteGameFromSegment: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το παιχνίδι <0>{{name}}</0> από το τμήμα;',
    deleteAllWhitelistedIpAddresses: 'Είστε σίγουροι ότι θέλετε να διαγράψετε όλες τις διευθύνσεις IP που βρίσκονται στη λίστα επιτρεπόμενων;',
    deleteWhitelistedIpAddress: 'Είστε σίγουροι ότι θέλετε να διαγράψετε τις διευθύνσεις IP που βρίσκονται στη λίστα επιτρεπόμενων;',
    deleteIpFromWhitelist: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το {{ip}} από τη λίστα σας;',
    changeView: 'Είστε σίγουροι ότι θέλετε να αλλάξετε την προβολή <0>{{name}}</0>;',
    cancelActiveBonuses: 'Είστε σίγουροι ότι θέλετε να ακυρώσετε όλα τα ενεργά μπόνους;',
    leavePage: 'Είστε σίγουροι ότι θέλετε να αποχωρήσετε από τη σελίδα;',
    removeFromCampaign: 'Είστε σίγουροι ότι θέλετε να αφαιρέσετε το μπόνους <0>{{name}}</0> από την καμπάνια;',
    removeParticipantsFromCampaign: 'Είστε σίγουροι ότι θέλετε να αφαιρέσετε συμμετέχοντες από την καμπάνια;',
    cancelPromotion: 'Είστε σίγουροι ότι θέλετε να ακυρώσετε την καμπάνια προωθητικής ενέργειας <0>{{name}}</0>;',
    makeAsDefault: 'Είστε σίγουροι ότι θέλετε να ορίσετε το <0>{{name}}</0> ως προεπιλογή;',
    resetAction: 'Είστε σίγουροι ότι θέλετε να επαναφέρετε το {{model}} για το <0>{{name}}</0>;',
    clean: 'Είστε σίγουροι ότι θέλετε να καθαρίσετε το <0>{{name}}</0> {{model}};',
    sync: 'Είστε σίγουροι ότι θέλετε να συγχρονίσετε το/τη <0>{{name}}</0>;',
    resendQRCode: 'Είστε σίγουροι ότι θέλετε να στείλετε ξανά τον κωδικό QR;',
    resendQRCodes: 'Είστε σίγουροι ότι θέλετε να στείλετε ξανά τους κωδικούς QR;',
    resetPrizeType: 'Είστε σίγουροι ότι θέλετε να επαναφέρετε τον τύπο;',
    resetSection: 'Είστε σίγουροι ότι θέλετε να επαναφέρετε την ενότητα;',
    publish: 'Είστε σίγουροι ότι θέλετε να δημοσιεύσετε το <0>{{name}}</0> {{model}};',
    resetTestPlayerPassword: 'Είστε σίγουροι ότι θέλετε να επαναφέρετε τον κωδικό πρόσβασης αυτού του λογαριασμού;',
    remove: 'Είστε σίγουροι ότι θέλετε να αφαιρέσετε το <0>{{name}}</0>;',
    deletePromotionLanguageContent: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το περιεχόμενο <0>{{language}}</0>; ',
    logout: 'Είστε σίγουροι ότι θέλετε να κάνετε Αποσύνδεση;',
    removeContentFromCampaign: 'Είστε σίγουροι ότι θέλετε να αφαιρέσετε το περιεχόμενο μπόνους <0>{{name}}</0> και την ειδοποίηση από την καμπάνια;',
  },
};

import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import qs from 'qs';
import mime from 'mimetypes';
import {
  changeEndedStatus, changeLoadingState, setErrorMessage, setSuccessMessage,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import { AppConstants } from 'constants/index';
import { downloadFile } from 'utils/utils';
import { getError, getMessage } from 'utils/helpers';

import {
  saveLobbies,
  saveLobby,
  saveLobbyProvider,
  saveLobbyProviders,
  saveLobbyProviderGamesOrders,
  saveLobbyProvidersOrders,
  saveCsvLobbyProviderGamesOrders,
  saveCsvLobbyProvidersOrders,
  saveLobbyLogs,
  saveLobbyGameGroups,
  saveLobbyGameGroup,
  saveLobbyGeneralWidgets,
  saveLobbyJackpotWidgets,
  saveLobbyGeneralWidget,
  saveLobbyJackpotWidget,
  saveLobbyWidget,
  saveLobbyWidgets,
  saveLobbyHomeCsvProviders,
} from 'actions/lobbyManagement/lobbyManagementActionCreator';
import { i18n, MessageService } from 'services';
import LobbyManagementActionType from 'actions/lobbyManagement/lobbyManagementActionType';

function* getLobbies({ payload }) {
  try {
    const { params } = payload;
    const options = {
      params,
    };
    const { Transaction, Lobbies } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies], options);
    yield put(saveLobbies(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobby({ payload }) {
  try {
    const { params, id } = payload;
    const options = {
      params,
    };
    const { Transaction, Lobbies } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, id], options);
    yield put(saveLobby(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobby({ payload }) {
  try {
    const { data, id, name } = payload;
    const options = {
      data,
    };
    const { Transaction, Lobbies } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, id], options);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeLobbyStatus({ payload }) {
  try {
    const { params, id, name } = payload;
    const options = {
      params,
    };
    const { Transaction, Lobbies, Status } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, id, Status], options);
    yield put(setSuccessMessage(getMessage('', i18n.t(!params.enable ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyProviders({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Lobbies, Providers } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, id, Providers], options);
    yield put(saveLobbyProviders(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeLobbyProviderStatus({ payload }) {
  try {
    const {
      params, lobbyId, providerId, name,
    } = payload;
    const options = {
      params,
    };
    const {
      Transaction, Lobbies, Providers, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, Providers, providerId, Status], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage('', i18n.t(!params.enable ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyProvider({ payload }) {
  try {
    const {
      lobbyId, data, providerId, name,
    } = payload;
    const options = {
      data,
    };
    const { Transaction, Lobbies, Providers } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, Providers, providerId], options);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyProvider({ payload }) {
  try {
    const { providerId, lobbyId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Lobbies, Providers } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, Providers, providerId], options);
    yield put(saveLobbyProvider(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* exportLobbyProviders({ payload }) {
  try {
    const { lobbyId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
      responseType: 'blob',
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Lobbies, Export, Providers,
    } = AppConstants.api;
    const { data, headers } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, Providers, Export], options);
    downloadFile(data, i18n.t('cms:lobbyProviders'), mime.detectExtension(headers['content-type']));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyProvidersOrders({ payload }) {
  try {
    const { params, lobbyId } = payload;
    const options = {
      params,
    };
    const {
      Transaction, Lobbies, Providers, Order,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, Providers, Order], options);
    yield put(saveLobbyProvidersOrders(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyProvidersOrders({ payload }) {
  try {
    const {
      lobbyId, data, params, name,
    } = payload;
    const options = {
      data,
      params,
    };
    const {
      Transaction, Lobbies, Providers, Order,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Providers, Order], options);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCsvLobbyProvidersOrders({ payload }) {
  try {
    const { lobbyId } = payload;
    yield put(changeLoadingState(true));
    const {
      Transaction, Lobbies, Providers, Upload,
    } = AppConstants.api;
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { data } = yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Providers, Upload], options);
    yield put(saveCsvLobbyProvidersOrders(data));
    yield put(changeLoadingState(false));
    if (!data.length) {
      MessageService.error(i18n.t('notification:emptyFile'));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

/**/
function* getLobbyProviderGamesOrders({ payload }) {
  try {
    const { params, lobbyId, providerId } = payload;
    const options = {
      params,
    };
    const {
      Transaction, Lobbies, Providers, Order, Games,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, Providers, providerId, Games, Order], options);
    yield put(saveLobbyProviderGamesOrders(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyProviderGamesOrders({ payload }) {
  try {
    const {
      lobbyId, providerId, data, params, name,
    } = payload;
    const options = {
      data,
      params,
    };
    const {
      Transaction, Lobbies, Providers, Order, Games,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Providers, providerId, Games, Order], options);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCsvLobbyProviderGamesOrders({ payload }) {
  try {
    const { lobbyId, providerId } = payload;
    yield put(changeLoadingState(true));
    const {
      Transaction, Lobbies, Providers, Upload, Games,
    } = AppConstants.api;
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { data } = yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Providers, providerId, Games, Upload], options);
    yield put(saveCsvLobbyProviderGamesOrders(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyLogs({ payload }) {
  try {
    const { lobbyId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Lobbies, Logs } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, Logs], options);
    yield put(saveLobbyLogs(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyGeneralWidgets({ payload }) {
  try {
    const { lobbyId, params } = payload;
    const options = { params };
    const { Transaction, Lobbies, GeneralWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, GeneralWidgets], options);
    yield put(saveLobbyGeneralWidgets(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyJackpotWidgets({ payload }) {
  try {
    const { lobbyId, params } = payload;
    const options = { params };
    const { Transaction, Lobbies, JackpotWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, JackpotWidgets], options);
    yield put(saveLobbyJackpotWidgets(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyGeneralWidget({ payload }) {
  try {
    const { lobbyId, widgetId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Lobbies, GeneralWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, GeneralWidgets, widgetId], options);
    yield put(saveLobbyGeneralWidget(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyJackpotWidget({ payload }) {
  try {
    const { lobbyId, widgetId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Lobbies, JackpotWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, JackpotWidgets, widgetId], options);
    yield put(saveLobbyJackpotWidget(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* manageLobbyJackpotWidget({ payload }) {
  try {
    const {
      lobbyId, widgetId, data, name,
    } = payload;
    const options = {
      data,
    };
    const { Transaction, Lobbies, JackpotWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, JackpotWidgets, widgetId], options);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* manageLobbyGeneralWidget({ payload }) {
  try {
    const {
      lobbyId, widgetId, data, name,
    } = payload;
    const options = {
      data,
    };
    const { Transaction, Lobbies, GeneralWidgets } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, GeneralWidgets, widgetId], options);
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeLobbyGeneralWidgetStatus({ payload }) {
  try {
    const {
      name, lobbyId, data, widgetId,
    } = payload;
    const options = { data };
    const {
      Transaction, Lobbies, GeneralWidgets, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, GeneralWidgets, widgetId, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeLobbyJackpotWidgetStatus({ payload }) {
  try {
    const {
      name, lobbyId, data, widgetId,
    } = payload;
    const options = { data };
    const {
      Transaction, Lobbies, JackpotWidgets, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, JackpotWidgets, widgetId, Status], options);
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
    yield put(setSuccessMessage(getMessage('', i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyGameGroups({ payload }) {
  try {
    const { params, lobbyId } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Lobbies, Groups } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, Groups], options);
    yield put(saveLobbyGameGroups(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* reorderLobbyGameGroup({ payload }) {
  try {
    const { lobbyId, groupId, data } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Lobbies, Groups, Order,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, Groups, groupId, Order], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeLobbyGameGroupStatus({ payload }) {
  try {
    const {
      lobbyId, groupId, data, name,
    } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Lobbies, Groups, Status,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, Groups, groupId, Status], options);
    yield put(setSuccessMessage(getMessage(i18n.t('cms:lGroup'), i18n.t(!data.status ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyGameGroup({ payload }) {
  try {
    const { lobbyId, groupId, params } = payload;
    const options = {
      params,
    };
    yield put(changeLoadingState(true));
    const { Transaction, Lobbies, Groups } = AppConstants.api;
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, Groups, groupId], options);
    yield put(saveLobbyGameGroup(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyGameGroup({ payload }) {
  try {
    const {
      lobbyId, groupId, data, name,
    } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const { Transaction, Lobbies, Groups } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, Groups, groupId], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('cms:lGroup'), i18n.t('notification:slUpdated'), name)));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyWidgets({ payload }) {
  try {
    const { lobbyId, params, sectionType } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const { Transaction, Lobbies, Widgets } = AppConstants.api;
    yield put(changeLoadingState(true));

    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, lobbyId, Widgets, sectionType], options);
    yield put(saveLobbyWidgets(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* reorderLobbyWidgets({ payload }) {
  try {
    const { lobbyId, widgetId, data } = payload;
    const options = {
      data,
    };
    yield put(changeLoadingState(true));
    const {
      Transaction, Lobbies, Widgets, Order,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, lobbyId, Widgets, widgetId, Order], options);
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyHomeGameWidget({ payload }) {
  try {
    const { widgetId } = payload;

    const {
      Transaction, Lobbies, Game, Widgets,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, Widgets, Game, widgetId]);
    yield put(saveLobbyWidget(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyHomeGameWidget({ payload }) {
  try {
    const { widgetId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, Game,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, Widgets, Game, widgetId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createLobbyHomeGameWidget({ payload }) {
  try {
    const { lobbyId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, Game,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Widgets, Game], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slCreated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteLobbyHomeGameWidget({ payload }) {
  try {
    const { id, lobbyId, name } = payload;

    const {
      Transaction, Lobbies, Widgets, Game,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, Lobbies, lobbyId, Widgets, Game, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slDeleted'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* changeLobbyWidgetStatus({ payload }) {
  try {
    const { widgetId, data, name } = payload;
    const options = {
      data,
    };

    const {
      Transaction, Lobbies, Widgets, Status,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, Widgets, widgetId, Status], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t(!data.enable ? 'notification:slDisabled' : 'notification:slEnabled'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyHomeTopWinWidget({ payload }) {
  try {
    const { widgetId } = payload;
    const {
      Transaction, Lobbies, Widgets, TopWins,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, Widgets, TopWins, widgetId]);
    yield put(saveLobbyWidget(data));
    yield put(changeLoadingState(false));

    yield put(saveLobbyWidget(data));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createLobbyHomeTopWinWidget({ payload }) {
  try {
    const { lobbyId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, TopWins,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Widgets, TopWins], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slCreated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyHomeTopWinWidget({ payload }) {
  try {
    const { widgetId, data, name } = payload;

    const options = { data };
    const {
      Transaction, Lobbies, Widgets, TopWins,
    } = AppConstants.api;

    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, Widgets, TopWins, widgetId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slUpdated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteLobbyHomeTopWinWidget({ payload }) {
  try {
    const { id, lobbyId, name } = payload;

    const {
      Transaction, Lobbies, Widgets, TopWins,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, Lobbies, lobbyId, Widgets, TopWins, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slDeleted'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyGroupWidget({ payload }) {
  try {
    const { id } = payload;
    const {
      Transaction, Lobbies, Widgets, GameGroup,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, Widgets, GameGroup, id]);
    yield put(saveLobbyWidget(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createLobbyGroupWidget({ payload }) {
  try {
    const {
      lobbyId, data, name, sectionType,
    } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, GameGroup,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Widgets, GameGroup, sectionType], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slCreated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyGroupWidget({ payload }) {
  try {
    const { widgetId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, GameGroup,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, Widgets, GameGroup, widgetId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteLobbyGroupWidget({ payload }) {
  try {
    const { id, lobbyId, name } = payload;
    const {
      Transaction, Lobbies, Widgets, GameGroup,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, Lobbies, lobbyId, Widgets, GameGroup, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slDeleted'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyActionLinkWidget({ payload }) {
  try {
    const { widgetId } = payload;

    const {
      Transaction, Lobbies, ActionLink, Widgets,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, Widgets, ActionLink, widgetId]);
    yield put(saveLobbyWidget(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyActionLinkWidget({ payload }) {
  try {
    const { widgetId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, ActionLink,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, Widgets, ActionLink, widgetId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createLobbyActionLinkWidget({ payload }) {
  try {
    const {
      lobbyId, data, name, sectionType,
    } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, ActionLink,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Widgets, ActionLink, sectionType], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slCreated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteLobbyActionLinkWidget({ payload }) {
  try {
    const { id, lobbyId, name } = payload;

    const {
      Transaction, Lobbies, Widgets, ActionLink,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, Lobbies, lobbyId, Widgets, ActionLink, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slDeleted'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyHomeTopXWidget({ payload }) {
  try {
    const { widgetId } = payload;

    const {
      Transaction, Lobbies, Widgets, TopX,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, Widgets, TopX, widgetId]);
    yield put(saveLobbyWidget(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyHomeTopXWidget({ payload }) {
  try {
    const { widgetId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, TopX,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, Widgets, TopX, widgetId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createLobbyHomeTopXWidget({ payload }) {
  try {
    const { lobbyId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, TopX,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Widgets, TopX], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slCreated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteLobbyHomeTopXWidget({ payload }) {
  try {
    const { id, lobbyId, name } = payload;

    const {
      Transaction, Lobbies, Widgets, TopX,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, Lobbies, lobbyId, Widgets, TopX, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slDeleted'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyHomeSingleGameWidget({ payload }) {
  try {
    const { widgetId } = payload;

    const {
      Transaction, Lobbies, Widgets, SingleGame,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, Widgets, SingleGame, widgetId]);
    yield put(saveLobbyWidget(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyHomeSingleGameWidget({ payload }) {
  try {
    const { widgetId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, SingleGame,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, Widgets, SingleGame, widgetId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createLobbyHomeSingleGameWidget({ payload }) {
  try {
    const { lobbyId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, SingleGame,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Widgets, SingleGame], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slCreated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteLobbyHomeSingleGameWidget({ payload }) {
  try {
    const { id, lobbyId, name } = payload;

    const {
      Transaction, Lobbies, Widgets, SingleGame,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, Lobbies, lobbyId, Widgets, SingleGame, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slDeleted'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyHomeProvidersAndGamesWidget({ payload }) {
  try {
    const { widgetId } = payload;

    const {
      Transaction, Lobbies, Widgets, ProviderWithGames,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, Widgets, ProviderWithGames, widgetId]);
    yield put(saveLobbyWidget(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyHomeProvidersAndGamesWidget({ payload }) {
  try {
    const { widgetId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, ProviderWithGames,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, Widgets, ProviderWithGames, widgetId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createLobbyHomeProvidersAndGamesWidget({ payload }) {
  try {
    const { lobbyId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, ProviderWithGames,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Widgets, ProviderWithGames], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slCreated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteLobbyHomeProvidersAndGamesWidget({ payload }) {
  try {
    const { id, lobbyId, name } = payload;

    const {
      Transaction, Lobbies, Widgets, ProviderWithGames,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, Lobbies, lobbyId, Widgets, ProviderWithGames, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slDeleted'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyProviderWidget({ payload }) {
  try {
    const { widgetId } = payload;

    const {
      Transaction, Lobbies, Widgets, Provider,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, Widgets, Provider, widgetId]);
    yield put(saveLobbyWidget(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createLobbyProviderWidget({ payload }) {
  try {
    const {
      lobbyId, data, name, sectionType,
    } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, Provider,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Widgets, Provider, sectionType], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slCreated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyProviderWidget({ payload }) {
  try {
    const { widgetId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, Provider,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, Widgets, Provider, widgetId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteLobbyProviderWidget({ payload }) {
  try {
    const { id, lobbyId, name } = payload;

    const {
      Transaction, Lobbies, Widgets, Provider,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, Lobbies, lobbyId, Widgets, Provider, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slDeleted'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyHomeCsvProviders({ payload }) {
  try {
    const { lobbyId } = payload;
    yield put(changeLoadingState(true));
    const {
      Transaction, Lobbies, Widgets, Provider, Upload,
    } = AppConstants.api;
    const options = {
      data: payload.data,
      headers: { 'content-type': 'multipart/form-data' },
    };
    const { data } = yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Widgets, Provider, Upload], options);
    yield put(saveLobbyHomeCsvProviders(data));
    yield put(changeLoadingState(false));
    if (!data.length) {
      MessageService.error(i18n.t('notification:emptyFile'));
    }
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyHomeRandomGameWidget({ payload }) {
  try {
    const { widgetId } = payload;

    const {
      Transaction, Lobbies, Widgets, RandomGame,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, Widgets, RandomGame, widgetId]);
    yield put(saveLobbyWidget(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createLobbyHomeRandomGameWidget({ payload }) {
  try {
    const { lobbyId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, RandomGame,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Widgets, RandomGame], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slCreated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyHomeRandomGameWidget({ payload }) {
  try {
    const { widgetId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, RandomGame,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, Widgets, RandomGame, widgetId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteLobbyHomeRandomGameWidget({ payload }) {
  try {
    const { id, lobbyId, name } = payload;

    const {
      Transaction, Lobbies, Widgets, RandomGame,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, Lobbies, lobbyId, Widgets, RandomGame, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slDeleted'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyHomeJackpotWidget({ payload }) {
  try {
    const { id } = payload;
    const {
      Transaction, Lobbies, Widgets, Jackpot,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, Widgets, Jackpot, id]);
    yield put(saveLobbyWidget(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createLobbyHomeJackpotWidget({ payload }) {
  try {
    const { lobbyId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, Jackpot,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Widgets, Jackpot], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slCreated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyHomeJackpotWidget({ payload }) {
  try {
    const { widgetId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, Jackpot,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, Widgets, Jackpot, widgetId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteLobbyHomeJackpotWidget({ payload }) {
  try {
    const { id, lobbyId, name } = payload;
    const {
      Transaction, Lobbies, Widgets, Jackpot,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, Lobbies, lobbyId, Widgets, Jackpot, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slDeleted'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyHomeCollectionWidget({ payload }) {
  try {
    const { id } = payload;
    const {
      Transaction, Lobbies, Widgets, Collection,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, Widgets, Collection, id]);
    yield put(saveLobbyWidget(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createLobbyHomeCollectionWidget({ payload }) {
  try {
    const { lobbyId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, Collection,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Widgets, Collection], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slCreated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyHomeCollectionWidget({ payload }) {
  try {
    const { widgetId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, Collection,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, Widgets, Collection, widgetId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteLobbyHomeCollectionWidget({ payload }) {
  try {
    const { id, lobbyId, name } = payload;
    const {
      Transaction, Lobbies, Widgets, Collection,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, Lobbies, lobbyId, Widgets, Collection, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slDeleted'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getLobbyHomeImageWidget({ payload }) {
  try {
    const { id } = payload;
    const {
      Transaction, Lobbies, Widgets, Image,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [Transaction, Lobbies, Widgets, Image, id]);
    yield put(saveLobbyWidget(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* createLobbyHomeImageWidget({ payload }) {
  try {
    const { lobbyId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, Image,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [Transaction, Lobbies, lobbyId, Widgets, Image], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slCreated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editLobbyHomeImageWidget({ payload }) {
  try {
    const { widgetId, data, name } = payload;
    const options = {
      data,
    };
    const {
      Transaction, Lobbies, Widgets, Image,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPutRequest, [Transaction, Lobbies, Widgets, Image, widgetId], options);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slUpdated'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteLobbyHomeImageWidget({ payload }) {
  try {
    const { id, lobbyId, name } = payload;
    const {
      Transaction, Lobbies, Widgets, Image,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIDeleteRequest, [Transaction, Lobbies, lobbyId, Widgets, Image, id]);
    yield put(setSuccessMessage(getMessage(i18n.t('lWidget'), i18n.t('notification:slDeleted'), name)));
    yield put(changeEndedStatus(true));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* lobbyManagementSaga() {
  yield all([
    takeLatest(LobbyManagementActionType.GET_LOBBIES, getLobbies),
    takeLatest(LobbyManagementActionType.GET_LOBBY_PROVIDERS, getLobbyProviders),
    takeLatest(LobbyManagementActionType.CHANGE_LOBBY_STATUS, changeLobbyStatus),
    takeLatest(LobbyManagementActionType.GET_LOBBY, getLobby),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY, editLobby),
    takeLatest(LobbyManagementActionType.CHANGE_LOBBY_PROVIDER_STATUS, changeLobbyProviderStatus),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_PROVIDER, editLobbyProvider),
    takeLatest(LobbyManagementActionType.GET_LOBBY_PROVIDER, getLobbyProvider),
    takeLatest(LobbyManagementActionType.EXPORT_LOBBY_PROVIDERS, exportLobbyProviders),
    takeLatest(LobbyManagementActionType.GET_LOBBY_PROVIDERS_ORDERS, getLobbyProvidersOrders),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_PROVIDERS_ORDERS, editLobbyProvidersOrders),
    takeLatest(LobbyManagementActionType.GET_CSV_LOBBY_PROVIDERS_ORDERS, getCsvLobbyProvidersOrders),
    takeLatest(LobbyManagementActionType.GET_LOBBY_PROVIDER_GAMES_ORDERS, getLobbyProviderGamesOrders),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_PROVIDER_GAMES_ORDERS, editLobbyProviderGamesOrders),
    takeLatest(LobbyManagementActionType.GET_CSV_LOBBY_PROVIDER_GAMES_ORDERS, getCsvLobbyProviderGamesOrders),
    takeLatest(LobbyManagementActionType.GET_LOBBY_LOGS, getLobbyLogs),
    takeLatest(LobbyManagementActionType.GET_LOBBY_GAME_GROUPS, getLobbyGameGroups),
    takeLatest(LobbyManagementActionType.REORDER_LOBBY_GAME_GROUP, reorderLobbyGameGroup),
    takeLatest(LobbyManagementActionType.CHANGE_LOBBY_GAME_GROUP_STATUS, changeLobbyGameGroupStatus),
    takeLatest(LobbyManagementActionType.GET_LOBBY_GAME_GROUP, getLobbyGameGroup),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_GAME_GROUP, editLobbyGameGroup),
    takeLatest(LobbyManagementActionType.GET_LOBBY_GENERAL_WIDGETS, getLobbyGeneralWidgets),
    takeLatest(LobbyManagementActionType.GET_LOBBY_JACKPOT_WIDGETS, getLobbyJackpotWidgets),
    takeLatest(LobbyManagementActionType.GET_LOBBY_GENERAL_WIDGET, getLobbyGeneralWidget),
    takeLatest(LobbyManagementActionType.GET_LOBBY_JACKPOT_WIDGET, getLobbyJackpotWidget),
    takeLatest(LobbyManagementActionType.MANAGE_LOBBY_GENERAL_WIDGET, manageLobbyGeneralWidget),
    takeLatest(LobbyManagementActionType.MANAGE_LOBBY_JACKPOT_WIDGET, manageLobbyJackpotWidget),
    takeLatest(LobbyManagementActionType.CHANGE_LOBBY_GENERAL_WIDGET_STATUS, changeLobbyGeneralWidgetStatus),
    takeLatest(LobbyManagementActionType.CHANGE_LOBBY_JACKPOT_WIDGET_STATUS, changeLobbyJackpotWidgetStatus),
    takeLatest(LobbyManagementActionType.GET_LOBBY_WIDGETS, getLobbyWidgets),
    takeLatest(LobbyManagementActionType.REORDER_LOBBY_WIDGETS, reorderLobbyWidgets),
    takeLatest(LobbyManagementActionType.GET_LOBBY_HOME_GAME_WIDGET, getLobbyHomeGameWidget),
    takeLatest(LobbyManagementActionType.CREATE_LOBBY_HOME_GAME_WIDGET, createLobbyHomeGameWidget),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_HOME_GAME_WIDGET, editLobbyHomeGameWidget),
    takeLatest(LobbyManagementActionType.DELETE_LOBBY_HOME_GAME_WIDGET, deleteLobbyHomeGameWidget),
    takeLatest(LobbyManagementActionType.CHANGE_LOBBY_WIDGET_STATUS, changeLobbyWidgetStatus),
    takeLatest(LobbyManagementActionType.GET_LOBBY_HOME_TOP_WIN_WIDGET, getLobbyHomeTopWinWidget),
    takeLatest(LobbyManagementActionType.CREATE_LOBBY_HOME_TOP_WIN_WIDGET, createLobbyHomeTopWinWidget),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_HOME_TOP_WIN_WIDGET, editLobbyHomeTopWinWidget),
    takeLatest(LobbyManagementActionType.DELETE_LOBBY_HOME_TOP_WIN_WIDGET, deleteLobbyHomeTopWinWidget),
    takeLatest(LobbyManagementActionType.GET_LOBBY_GROUP_WIDGET, getLobbyGroupWidget),
    takeLatest(LobbyManagementActionType.CREATE_LOBBY_GROUP_WIDGET, createLobbyGroupWidget),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_GROUP_WIDGET, editLobbyGroupWidget),
    takeLatest(LobbyManagementActionType.DELETE_LOBBY_GROUP_WIDGET, deleteLobbyGroupWidget),
    takeLatest(LobbyManagementActionType.GET_LOBBY_ACTION_LINK_WIDGET, getLobbyActionLinkWidget),
    takeLatest(LobbyManagementActionType.CREATE_LOBBY_ACTION_LINK_WIDGET, createLobbyActionLinkWidget),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_ACTION_LINK_WIDGET, editLobbyActionLinkWidget),
    takeLatest(LobbyManagementActionType.DELETE_LOBBY_ACTION_LINK_WIDGET, deleteLobbyActionLinkWidget),
    takeLatest(LobbyManagementActionType.GET_LOBBY_HOME_SINGLE_GAME_WIDGET, getLobbyHomeSingleGameWidget),
    takeLatest(LobbyManagementActionType.CREATE_LOBBY_HOME_SINGLE_GAME_WIDGET, createLobbyHomeSingleGameWidget),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_HOME_SINGLE_GAME_WIDGET, editLobbyHomeSingleGameWidget),
    takeLatest(LobbyManagementActionType.DELETE_LOBBY_HOME_SINGLE_GAME_WIDGET, deleteLobbyHomeSingleGameWidget),
    takeLatest(LobbyManagementActionType.GET_LOBBY_PROVIDER_WIDGET, getLobbyProviderWidget),
    takeLatest(LobbyManagementActionType.CREATE_LOBBY_PROVIDER_WIDGET, createLobbyProviderWidget),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_PROVIDER_WIDGET, editLobbyProviderWidget),
    takeLatest(LobbyManagementActionType.DELETE_LOBBY_PROVIDER_WIDGET, deleteLobbyProviderWidget),
    takeLatest(LobbyManagementActionType.GET_LOBBY_HOME_CSV_PROVIDERS, getLobbyHomeCsvProviders),
    takeLatest(LobbyManagementActionType.GET_LOBBY_HOME_TOP_X_WIDGET, getLobbyHomeTopXWidget),
    takeLatest(LobbyManagementActionType.CREATE_LOBBY_HOME_TOP_X_WIDGET, createLobbyHomeTopXWidget),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_HOME_TOP_X_WIDGET, editLobbyHomeTopXWidget),
    takeLatest(LobbyManagementActionType.DELETE_LOBBY_HOME_TOP_X_WIDGET, deleteLobbyHomeTopXWidget),
    takeLatest(LobbyManagementActionType.GET_LOBBY_HOME_RANDOM_GAME_WIDGET, getLobbyHomeRandomGameWidget),
    takeLatest(LobbyManagementActionType.CREATE_LOBBY_HOME_RANDOM_GAME_WIDGET, createLobbyHomeRandomGameWidget),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_HOME_RANDOM_GAME_WIDGET, editLobbyHomeRandomGameWidget),
    takeLatest(LobbyManagementActionType.DELETE_LOBBY_HOME_RANDOM_GAME_WIDGET, deleteLobbyHomeRandomGameWidget),
    takeLatest(LobbyManagementActionType.GET_LOBBY_HOME_PROVIDERS_AND_GAMES_WIDGET, getLobbyHomeProvidersAndGamesWidget),
    takeLatest(LobbyManagementActionType.CREATE_LOBBY_HOME_PROVIDERS_AND_GAMES_WIDGET, createLobbyHomeProvidersAndGamesWidget),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_HOME_PROVIDERS_AND_GAMES_WIDGET, editLobbyHomeProvidersAndGamesWidget),
    takeLatest(LobbyManagementActionType.DELETE_LOBBY_HOME_PROVIDERS_AND_GAMES_WIDGET, deleteLobbyHomeProvidersAndGamesWidget),
    takeLatest(LobbyManagementActionType.GET_LOBBY_HOME_JACKPOT_WIDGET, getLobbyHomeJackpotWidget),
    takeLatest(LobbyManagementActionType.CREATE_LOBBY_HOME_JACKPOT_WIDGET, createLobbyHomeJackpotWidget),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_HOME_JACKPOT_WIDGET, editLobbyHomeJackpotWidget),
    takeLatest(LobbyManagementActionType.DELETE_LOBBY_HOME_JACKPOT_WIDGET, deleteLobbyHomeJackpotWidget),
    takeLatest(LobbyManagementActionType.GET_LOBBY_HOME_COLLECTION_WIDGET, getLobbyHomeCollectionWidget),
    takeLatest(LobbyManagementActionType.CREATE_LOBBY_HOME_COLLECTION_WIDGET, createLobbyHomeCollectionWidget),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_HOME_COLLECTION_WIDGET, editLobbyHomeCollectionWidget),
    takeLatest(LobbyManagementActionType.DELETE_LOBBY_HOME_COLLECTION_WIDGET, deleteLobbyHomeCollectionWidget),
    takeLatest(LobbyManagementActionType.GET_LOBBY_HOME_IMAGE_WIDGET, getLobbyHomeImageWidget),
    takeLatest(LobbyManagementActionType.CREATE_LOBBY_HOME_IMAGE_WIDGET, createLobbyHomeImageWidget),
    takeLatest(LobbyManagementActionType.EDIT_LOBBY_HOME_IMAGE_WIDGET, editLobbyHomeImageWidget),
    takeLatest(LobbyManagementActionType.DELETE_LOBBY_HOME_IMAGE_WIDGET, deleteLobbyHomeImageWidget),
  ]);
}

import OperatorLimitActionType from 'actions/operatorLimit/operatorLimitActionType';

const initialState = {
  largeTransactionsGeneralLimits: [],
  largeTransactionLogs: {},
  playerLimits: {},
  responsibleGamingLimits: {},
  largeTransactionsWinLimits: {},
};

const operatorLimitReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case OperatorLimitActionType.SAVE_LARGE_TRANSACTIONS_GENERAL_LIMITS:
    return {
      ...state,
      largeTransactionsGeneralLimits: payload.data,
    };
  case OperatorLimitActionType.RESET_LARGE_TRANSACTIONS_GENERAL_LIMITS:
    return {
      ...state,
      largeTransactionsGeneralLimits: [],
    };
  case OperatorLimitActionType.SAVE_LARGE_TRANSACTION_LIMITS_LOGS:
    return {
      ...state,
      largeTransactionLogs: payload.data,
    };
  case OperatorLimitActionType.RESET_LARGE_TRANSACTION_LIMITS_LOGS:
    return {
      ...state,
      largeTransactionLogs: {},
    };
  case OperatorLimitActionType.SAVE_BRAND_PLAYER_LIMITS:
    return {
      ...state,
      playerLimits: payload.data,
    };
  case OperatorLimitActionType.RESET_BRAND_PLAYER_LIMITS:
    return {
      ...state,
      playerLimits: {},
    };
  case OperatorLimitActionType.SAVE_RESPONSIBLE_GAMING_LIMITS:
    return {
      ...state,
      responsibleGamingLimits: payload.data,
    };
  case OperatorLimitActionType.RESET_RESPONSIBLE_GAMING_LIMITS:
    return {
      ...state,
      responsibleGamingLimits: {},
    };
  case OperatorLimitActionType.SAVE_LARGE_TRANSACTIONS_WIN_LIMITS:
    return {
      ...state,
      largeTransactionsWinLimits: payload.data,
    };
  case OperatorLimitActionType.RESET_LARGE_TRANSACTIONS_WIN_LIMITS:
    return {
      ...state,
      largeTransactionsWinLimits: {},
    };
  default:
    return state;
  }
};

export default operatorLimitReducer;

import _ from 'lodash';
import { createSelector } from 'reselect';

export const selectGameLoading = (state) => state.loading;
export const selectGameProvidersResource = createSelector(
  (state, key = 'first') => state.providersResource[key],
  (providersResource) => _.sortBy(providersResource, 'name'),
);
export const selectSegmentGamesResource = (state) => state.segmentGamesResource;
export const selectGamesResource = (state, key = 'first') => state.gamesResource?.[key];
const selectProviders = (state) => state.providers;
export const selectProvidersData = (state) => selectProviders(state).data;
export const selectProvidersTotal = (state) => selectProviders(state).total;
export const selectProviderGames = (state) => state.providerGames;
const selectBrandGameProviders = (state) => state.brandProviders;
export const selectBrandGameProvidersData = (state) => selectBrandGameProviders(state).data;
export const selectBrandGameProvidersTotal = (state) => selectBrandGameProviders(state).total;
const selectBrandProviderGames = (state) => state.brandProviderGames;
export const selectBrandProviderGamesData = (state) => selectBrandProviderGames(state).data;
export const selectBrandProviderGamesTotal = (state) => selectBrandProviderGames(state).total;
export const selectFreeGameProviders = createSelector(
  (state) => state.freeGameProviders,
  (freeGameProviders) => _.sortBy(freeGameProviders, 'providerName'),
);
export const selectFreeGamesV2Providers = createSelector(
  (state) => state.freeGamesV2Providers,
  (freeGameProviders) => _.sortBy(freeGameProviders, 'providerName'),
);
export const selectFreeGames = (state) => state.freeGames;
export const selectFreeGamesV2 = (state) => state.freeGamesV2;

export const selectFreeGameAmountRestrictions = (state) => state.freeGameAmountRestrictions;
export const selectFreeGamesV2AmountRestrictions = (state) => state.freeGamesV2AmountRestrictions;

export const selectFreeGameLoading = (state) => state.freeGameLoading;
export const selectFreeGameAmountLoading = (state) => state.freeGameAmountLoading;

export const selectGameProvidersResourceLoading = (state) => state.providersResourceLoading;

const selectGameProvider = (state) => state.gameProvider;

export const selectGameProviderName = (state) => selectGameProvider(state).name;

export const selectBrandJackpotFeeds = (state) => state.jackpotFeeds;

const selectBrandJackpotFeedGames = (state) => state.jackpotFeedGames;
export const selectBrandJackpotFeedGamesData = (state) => selectBrandJackpotFeedGames(state).data;
export const selectBrandJackpotFeedGamesTotal = (state) => selectBrandJackpotFeedGames(state).total;

import { createSelector } from 'reselect';

const selectClosedAccounts = (state) => state.closedAccounts;
export const selectClosedAccountsData = (state) => selectClosedAccounts(state).data;
export const selectClosedAccountsTotal = (state) => selectClosedAccounts(state).total;
const selectLargeTransactions = (state) => state.largeTransactions;
export const selectLargeTransactionsData = (state) => selectLargeTransactions(state).data;
export const selectLargeTransactionsTotal = (state) => selectLargeTransactions(state).total;
export const selectLargeTransactionRecipients = (state) => state.largeTransactionRecipients;
const selectLargeTransactionsByBrand = (state) => state.largeTransactionsByBrand;
export const selectLargeTransactionsByBrandData = (state) => selectLargeTransactionsByBrand(state).data;
export const selectLargeTransactionsByBrandTotal = (state) => selectLargeTransactionsByBrand(state).total;
export const selectLargeTransactionsByBrandTotalData = (state) => selectLargeTransactionsByBrand(state).totalData;
const selectLargeTransactionsByPlayer = (state) => state.largeTransactionsByPlayer;
export const selectLargeTransactionsByPlayerData = (state) => selectLargeTransactionsByPlayer(state).data;
export const selectLargeTransactionsByPlayerTotal = (state) => selectLargeTransactionsByPlayer(state).total;
export const selectLargeTransactionsByPlayerTotalData = (state) => selectLargeTransactionsByPlayer(state).totalData;
const selectNegativeBalance = (state) => state.negativeBalance;
export const selectNegativeBalanceData = (state) => selectNegativeBalance(state).data;
export const selectNegativeBalanceTotal = (state) => selectNegativeBalance(state).total;
const selectBalanceAdjustments = (state) => state.balanceAdjustments;
export const selectBalanceAdjustmentsReportData = (state) => selectBalanceAdjustments(state).data;
export const selectBalanceAdjustmentsReportTotal = (state) => selectBalanceAdjustments(state).total;
const selectBiBetByPlayer = (state) => state.biBetByPlayer;
export const selectBiBetByPlayerData = (state) => selectBiBetByPlayer(state).data;
export const selectBiBetByPlayerTotal = (state) => selectBiBetByPlayer(state).total;
const selectBiBetByCurrency = (state) => state.biBetByCurrency;
export const selectBiBetByCurrencyData = (state) => selectBiBetByCurrency(state).data;
export const selectBiBetByCurrencyTotal = (state) => selectBiBetByCurrency(state).total;
const selectBiFinancialByPlayer = (state) => state.biFinancialByPlayer;
export const selectBiFinancialByPlayerData = (state) => selectBiFinancialByPlayer(state).data;
export const selectBiFinancialByPlayerTotal = (state) => selectBiFinancialByPlayer(state).total;
const selectBiFinancialByCurrency = (state) => state.biFinancialByCurrency;
export const selectBiFinancialByCurrencyData = (state) => selectBiFinancialByCurrency(state).data;
export const selectBiFinancialByCurrencyTotal = (state) => selectBiFinancialByCurrency(state).total;
const selectPlayersWallets = (state) => state.playersWallets;
export const selectPlayerWalletsData = (state) => selectPlayersWallets(state).data;
export const selectPlayerWalletsTotal = (state) => selectPlayersWallets(state).total;
export const selectFinancialByProvider = (state) => state.financialByProvider;
export const selectProvidersTopNegativeGgrs = (state) => state.providersTopNegativeGgrs;
export const selectProviderTopNegativeGgrs = (state, id) => createSelector(
  () => selectProvidersTopNegativeGgrs(state),
  (data) => data[id] || [],
)();
export const selectProvidersTopPositiveGgrs = (state) => state.providersTopPositiveGgrs;
export const selectProviderTopPositiveGgrs = (state, id) => createSelector(
  () => selectProvidersTopPositiveGgrs(state),
  (data) => data[id] || [],
)();
const selectPlayersFirstDeposits = (state) => state.playersFirstDeposits;
export const selectPlayersFirstDepositsData = (state) => selectPlayersFirstDeposits(state).data;
export const selectPlayersFirstDepositsTotal = (state) => selectPlayersFirstDeposits(state).total;

const selectBonusTotalsByPlayer = (state) => state.bonusTotalsByPlayer;
export const selectBonusTotalsByPlayerData = (state) => selectBonusTotalsByPlayer(state)?.data;
export const selectBonusTotalsByPlayerTotal = (state) => selectBonusTotalsByPlayer(state)?.total;

const selectBonusTotalsByType = (state) => state.bonusTotalsByType;
export const selectBonusTotalsByTypeData = (state) => selectBonusTotalsByType(state)?.data;
export const selectBonusTotalsByTypeTotal = (state) => selectBonusTotalsByType(state)?.total;

const selectBonusTotalsByStatus = (state) => state.bonusTotalsByStatus;
export const selectBonusTotalsByStatusData = (state) => selectBonusTotalsByStatus(state)?.data;
export const selectBonusTotalsByStatusTotal = (state) => selectBonusTotalsByStatus(state)?.total;

const selectBonusTotalsByProvider = (state) => state.bonusTotalsByProvider;
export const selectBonusTotalsByProviderData = (state) => selectBonusTotalsByProvider(state)?.data;
export const selectBonusTotalsByProviderTotal = (state) => selectBonusTotalsByProvider(state)?.total;

const selectInactivePlayers = (state) => state.inactivePlayers;
export const selectInactivePlayersData = (state) => selectInactivePlayers(state).data;
export const selectInactivePlayersTotal = (state) => selectInactivePlayers(state).total;

export const selectTopPlayersByGgr = (state) => state.topPlayersByGgr;

export const selectTopPlayersByGgrDetails = (state) => state.topPlayersByGgrDetails;
export const selectTopPlayerByGgrDetails = (state, playerId) => createSelector(
  () => selectTopPlayersByGgrDetails(state),
  (data) => data[playerId] || [],
)();

export const selectTopPlayersByTransactionType = (state) => state.topPlayersByTransactionType;

export const selectTopPlayersByTransactionTypeDetails = (state) => state.topPlayersByTransactionTypeDetails;
export const selectTopPlayerByTransactionTypeDetails = (state, playerId) => createSelector(
  () => selectTopPlayersByTransactionTypeDetails(state),
  (data) => data[playerId] || [],
)();

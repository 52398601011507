import {
  all, call, put, select, takeLatest,
} from '@redux-saga/core/effects';
import qs from 'qs';
import CashbackV2ActionType from 'actions/cashBackV2/cashBackV2ActionType';
import { AppConstants } from 'constants/index';
import {
  changeLoadingState, setErrorMessage, setSuccessMessage, changeEndedStatus,
} from 'actions/setting/settingActionCreator';
import NetworkService from 'services/networkService';
import {
  saveCashBacksV2, saveCashBackV2, savePlayerCashBackV2Periods, savePlayerCashBacksV2, saveCashBackV2Statistics, saveCashBackV2Players,
} from 'actions/cashBackV2/cashBackV2ActionCreator';
import { i18n } from 'services';
import { getError, getMessage } from 'utils/helpers';
import { selectPlayerCashBackV2Periods } from 'selectors';
import { saveCashBackIncludedPlayers } from 'actions/cashBack/cashBackActionCreator';

function* createCashBackV2({ payload }) {
  try {
    const { name } = payload.data;
    const { DataCollector, Cashback, V2 } = AppConstants.api;
    yield put(changeLoadingState(true));
    yield call(NetworkService.makeAPIPostRequest, [DataCollector, Cashback, V2], payload);
    yield put(setSuccessMessage(getMessage(i18n.t('lCashback'), i18n.t('notification:slCreated'), name)));
    yield put(changeLoadingState(false));
    yield put(changeEndedStatus(true));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashBacksV2({ payload }) {
  try {
    const { id, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      DataCollector, Cashback, V2, ByBrand,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, V2, ByBrand, id], options);
    yield put(saveCashBacksV2(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashBackV2({ payload }) {
  try {
    const { id, params } = payload;
    const { DataCollector, Cashback, V2 } = AppConstants.api;
    yield put(changeLoadingState(true));
    const response = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, V2, id], { params });
    yield put(saveCashBackV2(response.data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* editCashBackV2({ payload }) {
  try {
    const { name } = payload.data;
    yield put(changeLoadingState(true));
    const { DataCollector, Cashback, V2 } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Cashback, V2], payload);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lCashback'), i18n.t('notification:slUpdated'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCashBackV2({ payload }) {
  try {
    const { id, name } = payload.data;
    yield put(changeLoadingState(true));
    const { DataCollector, Cashback, V2 } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Cashback, V2, id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lCashback'), i18n.t('notification:slDeleted'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* enableCashBackV2({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    yield put(changeLoadingState(true));
    const {
      DataCollector, Cashback, Enable, V2,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Cashback, V2, Enable, id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lCashback'), i18n.t('notification:slEnabled'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* disableCashBackV2({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    yield put(changeLoadingState(true));
    const {
      DataCollector, Cashback, Disable, V2,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Cashback, V2, Disable, id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lCashback'), i18n.t('notification:slDisabled'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* finishCashBackV2({ payload }) {
  try {
    const { id, data } = payload;
    const { name } = data;
    yield put(changeLoadingState(true));
    const {
      DataCollector, Cashback, Finish, V2,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Cashback, V2, Finish, id]);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('lCashback'), i18n.t('notification:slFinished'), name)));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerCashBacksV2({ payload }) {
  try {
    const { playerId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      DataCollector, Cashback, ByPlayer, V2,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, V2, ByPlayer, playerId], options);
    yield put(savePlayerCashBacksV2(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getPlayerCashBackV2Periods({ payload }) {
  try {
    const { cashbackId, playerId } = payload;
    const {
      DataCollector, Cashback, V2, ByPlayerPeriods,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, V2, ByPlayerPeriods, cashbackId, playerId]);
    const { cashBackV2 } = yield select();
    const periods = selectPlayerCashBackV2Periods(cashBackV2);
    periods[cashbackId] = data;
    yield put(savePlayerCashBackV2Periods(periods));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashBackV2Statistics({ payload }) {
  try {
    const { cashbackId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      DataCollector, Cashback, V2, Statistics,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, V2, Statistics, cashbackId], options);
    yield put(saveCashBackV2Statistics(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashBackV2Players({ payload }) {
  try {
    const { cashbackId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    const {
      DataCollector, Cashback, V2, IncludedPlayer,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, V2, IncludedPlayer, cashbackId], options);
    yield put(saveCashBackV2Players(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err, true);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* deleteCashBackV2IncludedPlayer({ payload }) {
  try {
    const { id, params } = payload;
    const { userName, cashbackId } = params;
    yield put(changeLoadingState(true));
    const {
      DataCollector, Cashback, V2, IncludedPlayer,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIDeleteRequest, [DataCollector, Cashback, V2, IncludedPlayer, id], { params: { cashbackId } });
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(i18n.t('notification:playerRemovedFromRestrictionList', { name: userName })));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* getCashBackV2IncludedPlayers({ payload }) {
  try {
    const { cashbackId } = payload;
    const {
      DataCollector, Cashback, V2, ManageIncludedPlayer,
    } = AppConstants.api;
    yield put(changeLoadingState(true));
    const { data } = yield call(NetworkService.makeAPIGetRequest, [DataCollector, Cashback, V2, ManageIncludedPlayer, cashbackId]);
    yield put(saveCashBackIncludedPlayers(data));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

function* updateCashBackV2IncludedPlayers({ payload }) {
  try {
    const { cashbackId, params } = payload;
    const options = {
      params,
      paramsSerializer: (param) => qs.stringify(param),
    };
    yield put(changeLoadingState(true));
    const {
      DataCollector, Cashback, IncludedPlayer, V2,
    } = AppConstants.api;
    yield call(NetworkService.makeAPIPutRequest, [DataCollector, Cashback, V2, IncludedPlayer, cashbackId], options);
    yield put(changeEndedStatus(true));
    yield put(setSuccessMessage(getMessage(i18n.t('bonus:playersCashBack'), i18n.t('notification:slUpdated'))));
    yield put(changeLoadingState(false));
  } catch (err) {
    if (err) {
      const error = getError(err);
      yield put(setErrorMessage(error));
    }
    yield put(changeLoadingState(false));
  }
}

export default function* cashBackV2Saga() {
  yield all([
    takeLatest(CashbackV2ActionType.CREATE_CASH_BACK_V2, createCashBackV2),
    takeLatest(CashbackV2ActionType.GET_CASH_BACKS_V2, getCashBacksV2),
    takeLatest(CashbackV2ActionType.GET_CASH_BACK_V2, getCashBackV2),
    takeLatest(CashbackV2ActionType.DELETE_CASH_BACK_V2, deleteCashBackV2),
    takeLatest(CashbackV2ActionType.ENABLE_CASH_BACK_V2, enableCashBackV2),
    takeLatest(CashbackV2ActionType.DISABLE_CASH_BACK_V2, disableCashBackV2),
    takeLatest(CashbackV2ActionType.FINISH_CASH_BACK_V2, finishCashBackV2),
    takeLatest(CashbackV2ActionType.EDIT_CASH_BACK_V2, editCashBackV2),
    takeLatest(CashbackV2ActionType.GET_PLAYER_CASH_BACKS_V2, getPlayerCashBacksV2),
    takeLatest(CashbackV2ActionType.GET_PLAYER_CASH_BACK_V2_PERIODS, getPlayerCashBackV2Periods),
    takeLatest(CashbackV2ActionType.GET_CASH_BACK_V2_STATISTICS, getCashBackV2Statistics),
    takeLatest(CashbackV2ActionType.GET_CASH_BACK_V2_PLAYERS, getCashBackV2Players),
    takeLatest(CashbackV2ActionType.DELETE_CASH_BACK_V2_INCLUDED_PLAYER, deleteCashBackV2IncludedPlayer),
    takeLatest(CashbackV2ActionType.GET_CASH_BACK_V2_INCLUDED_PLAYERS, getCashBackV2IncludedPlayers),
    takeLatest(CashbackV2ActionType.UPDATE_CASH_BACK_V2_INCLUDED_PLAYERS, updateCashBackV2IncludedPlayers),
  ]);
}

const GameActionType = {
  GET_GAME_PROVIDERS_RESOURCE: 'GET_GAME_PROVIDERS_RESOURCE',
  SAVE_GAME_PROVIDERS_RESOURCE: 'SAVE_GAME_PROVIDERS_RESOURCE',
  RESET_GAME_PROVIDERS_RESOURCE: 'RESET_GAME_PROVIDERS_RESOURCE',

  GET_BRAND_GAME_PROVIDERS: 'GET_BRAND_GAME_PROVIDERS',
  SAVE_BRAND_GAME_PROVIDERS: 'SAVE_BRAND_GAME_PROVIDERS',
  RESET_BRAND_GAME_PROVIDERS: 'RESET_BRAND_GAME_PROVIDERS',
  EXPORT_BRAND_GAME_PROVIDERS: 'EXPORT_BRAND_GAME_PROVIDERS',

  GET_GAMES_RESOURCE: 'GET_GAMES_RESOURCE',
  SAVE_GAMES_RESOURCE: 'SAVE_GAMES_RESOURCE',
  RESET_GAMES_RESOURCE: 'RESET_GAMES_RESOURCE',

  GET_SEGMENT_GAMES_RESOURCE: 'GET_SEGMENT_GAMES_RESOURCE',
  SAVE_SEGMENT_GAMES_RESOURCE: 'SAVE_SEGMENT_GAMES_RESOURCE',
  RESET_SEGMENT_GAMES_RESOURCE: 'RESET_SEGMENT_GAMES_RESOURCE',

  CHANGE_GAME_LOADING_STATE: 'CHANGE_GAME_LOADING_STATE',

  GET_BRAND_PROVIDER_GAMES: 'GET_BRAND_PROVIDER_GAMES',
  SAVE_BRAND_PROVIDER_GAMES: 'SAVE_BRAND_PROVIDER_GAMES',
  RESET_BRAND_PROVIDER_GAMES: 'RESET_BRAND_PROVIDER_GAMES',
  EXPORT_BRAND_PROVIDER_GAMES: 'EXPORT_BRAND_PROVIDER_GAMES',

  GET_GAME_PROVIDERS: 'GET_GAME_PROVIDERS',
  SAVE_GAME_PROVIDERS: 'SAVE_GAME_PROVIDERS',
  RESET_GAME_PROVIDERS: 'RESET_GAME_PROVIDERS',

  GET_PROVIDER_GAMES: 'GET_PROVIDER_GAMES',
  SAVE_PROVIDER_GAMES: 'SAVE_PROVIDER_GAMES',
  RESET_PROVIDER_GAMES: 'RESET_PROVIDER_GAMES',

  // please delete after campaign v2 be ready
  GET_FREE_GAME_PROVIDERS: 'GET_FREE_GAME_PROVIDERS',
  SAVE_FREE_GAME_PROVIDERS: 'SAVE_FREE_GAME_PROVIDERS',
  RESET_FREE_GAME_PROVIDERS: 'RESET_FREE_GAME_PROVIDERS',

  GET_FREE_GAMES_V2_PROVIDERS: 'GET_FREE_GAMES_V2_PROVIDERS',
  SAVE_FREE_GAMES_V2_PROVIDERS: 'SAVE_FREE_GAMES_V2_PROVIDERS',
  RESET_FREE_GAMES_V2_PROVIDERS: 'RESET_FREE_GAMES_V2_PROVIDERS',

  GET_FREE_GAMES: 'GET_FREE_GAMES',
  SAVE_FREE_GAMES: 'SAVE_FREE_GAMES',
  RESET_FREE_GAMES: 'RESET_FREE_GAMES',

  GET_FREE_GAMES_V2: 'GET_FREE_GAMES_V2',
  SAVE_FREE_GAMES_V2: 'SAVE_FREE_GAMES_V2',
  RESET_FREE_GAMES_V2: 'RESET_FREE_GAMES_V2',

  GET_FREE_GAME_AMOUNT_RESTRICTIONS: 'GET_FREE_GAME_AMOUNT_RESTRICTIONS',
  SAVE_FREE_GAME_AMOUNT_RESTRICTIONS: 'SAVE_FREE_GAME_AMOUNT_RESTRICTIONS',
  RESET_FREE_GAME_AMOUNT_RESTRICTIONS: 'RESET_FREE_GAME_AMOUNT_RESTRICTIONS',

  CHANGE_FREE_GAME_LOADING_STATE: 'CHANGE_FREE_GAME_LOADING_STATE',
  CHANGE_FREE_GAME_AMOUNT_LOADING_STATE: 'CHANGE_FREE_GAME_AMOUNT_LOADING_STATE',
  CHANGE_PROVIDERS_RESOURCE_LOADING_STATE: 'CHANGE_PROVIDERS_RESOURCE_LOADING_STATE',

  GET_GAME_PROVIDER: 'GET_GAME_PROVIDER',
  SAVE_GAME_PROVIDER: 'SAVE_GAME_PROVIDER',
  RESET_GAME_PROVIDER: 'RESET_GAME_PROVIDER',

  GET_BRAND_JACKPOT_FEEDS: 'GET_BRAND_JACKPOT_FEEDS',
  SAVE_BRAND_JACKPOT_FEEDS: 'SAVE_BRAND_JACKPOT_FEEDS',
  RESET_BRAND_JACKPOT_FEEDS: 'RESET_BRAND_JACKPOT_FEEDS',

  CHANGE_BRAND_JACKPOT_FEED_STATUS: 'CHANGE_BRAND_JACKPOT_FEED_STATUS',

  GET_BRAND_JACKPOT_FEED_GAMES: 'GET_BRAND_JACKPOT_FEED_GAMES',
  SAVE_BRAND_JACKPOT_FEED_GAMES: 'SAVE_BRAND_JACKPOT_FEED_GAMES',
  RESET_BRAND_JACKPOT_FEED_GAMES: 'RESET_BRAND_JACKPOT_FEED_GAMES',

  EXPORT_BRAND_JACKPOT_FEED_GAMES: 'EXPORT_BRAND_JACKPOT_FEED_GAMES',

  GET_FREE_GAMES_V2_AMOUNT_RESTRICTIONS: 'GET_FREE_GAMES_V2_AMOUNT_RESTRICTIONS',
  SAVE_FREE_GAMES_V2_AMOUNT_RESTRICTIONS: 'SAVE_FREE_GAMES_V2_AMOUNT_RESTRICTIONS',
  RESET_FREE_GAMES_V2_AMOUNT_RESTRICTIONS: 'RESET_FREE_GAMES_V2_AMOUNT_RESTRICTIONS',
};

export default GameActionType;

import CashBackV2ActionType from './cashBackV2ActionType';

export const createCashBackV2 = (data) => ({
  type: CashBackV2ActionType.CREATE_CASH_BACK_V2,
  payload: { data },
});

export const getCashBacksV2 = (id, params) => ({
  type: CashBackV2ActionType.GET_CASH_BACKS_V2,
  payload: { id, params },
});

export const saveCashBacksV2 = (data) => ({
  type: CashBackV2ActionType.SAVE_CASH_BACKS_V2,
  payload: { data },
});

export const resetCashBacksV2 = () => ({
  type: CashBackV2ActionType.RESET_CASH_BACKS_V2,
});

export const getCashBackV2 = (id, params) => ({
  type: CashBackV2ActionType.GET_CASH_BACK_V2,
  payload: { id, params },
});

export const saveCashBackV2 = (data) => ({
  type: CashBackV2ActionType.SAVE_CASH_BACK_V2,
  payload: { data },
});

export const resetCashBackV2 = () => ({
  type: CashBackV2ActionType.RESET_CASH_BACK_V2,
});

export const editCashBackV2 = (data) => ({
  type: CashBackV2ActionType.EDIT_CASH_BACK_V2,
  payload: { data },
});

export const deleteCashBackV2 = (data) => ({
  type: CashBackV2ActionType.DELETE_CASH_BACK_V2,
  payload: { data },
});

export const enableCashBackV2 = (id, data) => ({
  type: CashBackV2ActionType.ENABLE_CASH_BACK_V2,
  payload: { id, data },
});

export const disableCashBackV2 = (id, data) => ({
  type: CashBackV2ActionType.DISABLE_CASH_BACK_V2,
  payload: { id, data },
});

export const finishCashBackV2 = (id, data) => ({
  type: CashBackV2ActionType.FINISH_CASH_BACK_V2,
  payload: { id, data },
});

export const getPlayerCashBacksV2 = (playerId, params) => ({
  type: CashBackV2ActionType.GET_PLAYER_CASH_BACKS_V2,
  payload: { playerId, params },
});

export const savePlayerCashBacksV2 = (data) => ({
  type: CashBackV2ActionType.SAVE_PLAYER_CASH_BACKS_V2,
  payload: { data },
});

export const resetPlayerCashBacksV2 = () => ({
  type: CashBackV2ActionType.RESET_PLAYER_CASH_BACKS_V2,
});

export const exportPlayerCashBacksV2 = (playerId, params) => ({
  type: CashBackV2ActionType.EXPORT_PLAYER_CASH_BACKS_V2,
  payload: { playerId, params },
});

export const getCashBackV2Statistics = (cashbackId, params) => ({
  type: CashBackV2ActionType.GET_CASH_BACK_V2_STATISTICS,
  payload: { cashbackId, params },
});

export const saveCashBackV2Statistics = (data) => ({
  type: CashBackV2ActionType.SAVE_CASH_BACK_V2_STATISTICS,
  payload: { data },
});

export const resetCashBackV2Statistics = () => ({
  type: CashBackV2ActionType.RESET_CASH_BACK_V2_STATISTICS,
});

export const getPlayerCashBackV2Periods = (cashbackId, playerId) => ({
  type: CashBackV2ActionType.GET_PLAYER_CASH_BACK_V2_PERIODS,
  payload: {
    cashbackId,
    playerId,
  },
});

export const savePlayerCashBackV2Periods = (data) => ({
  type: CashBackV2ActionType.SAVE_PLAYER_CASH_BACK_V2_PERIODS,
  payload: { data },
});

export const resetPlayerCashBackV2Periods = () => ({
  type: CashBackV2ActionType.RESET_PLAYER_CASH_BACK_V2_PERIODS,
});

export const getCashBackV2Players = (cashbackId, params) => ({
  type: CashBackV2ActionType.GET_CASH_BACK_V2_PLAYERS,
  payload: { cashbackId, params },
});

export const saveCashBackV2Players = (data) => ({
  type: CashBackV2ActionType.SAVE_CASH_BACK_V2_PLAYERS,
  payload: { data },
});

export const resetCashBackV2Players = () => ({
  type: CashBackV2ActionType.RESET_CASH_BACK_V2_PLAYERS,
});

export const getCashBackV2IncludedPlayers = (cashbackId) => ({
  type: CashBackV2ActionType.GET_CASH_BACK_V2_INCLUDED_PLAYERS,
  payload: { cashbackId },
});

export const saveCashBackV2IncludedPlayers = (data) => ({
  type: CashBackV2ActionType.SAVE_CASH_BACK_V2_INCLUDED_PLAYERS,
  payload: { data },
});

export const resetCashBackV2IncludedPlayers = () => ({
  type: CashBackV2ActionType.RESET_CASH_BACK_V2_INCLUDED_PLAYERS,
});

export const updateCashBackV2IncludedPlayers = (cashbackId, params) => ({
  type: CashBackV2ActionType.UPDATE_CASH_BACK_V2_INCLUDED_PLAYERS,
  payload: { cashbackId, params },
});

export const deleteCashBackV2IncludedPlayer = (id, params) => ({
  type: CashBackV2ActionType.DELETE_CASH_BACK_V2_INCLUDED_PLAYER,
  payload: { id, params },
});

import GameActionType from 'actions/game/gameActionType';

const initialState = {
  loading: false,
  providersResource: {},
  segmentGamesResource: [],
  gamesResource: {},
  providers: {},
  providerGames: [],
  brandProviders: {},
  brandProviderGames: {},
  freeGameProviders: [],
  freeGamesV2Providers: [],
  freeGames: [],
  freeGamesV2: [],
  freeGameAmountRestrictions: {},
  freeGamesV2AmountRestrictions: [],
  freeGameLoading: false,
  freeGameAmountLoading: false,
  providersResourceLoading: false,
  gameProvider: {},
  jackpotFeeds: [],
  jackpotFeedGames: {},
};

const gameReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case GameActionType.SAVE_GAME_PROVIDERS_RESOURCE:
    return {
      ...state,
      providersResource: {
        ...state.providersResource,
        [payload.key]: payload.data,
      },
    };
  case GameActionType.CHANGE_PROVIDERS_RESOURCE_LOADING_STATE:
    return {
      ...state,
      providersResourceLoading: payload.state,
    };
  case GameActionType.CHANGE_GAME_LOADING_STATE:
    return {
      ...state,
      loading: payload.state,
    };
  case GameActionType.SAVE_SEGMENT_GAMES_RESOURCE:
    return {
      ...state,
      segmentGamesResource: payload.data,
    };
  case GameActionType.RESET_SEGMENT_GAMES_RESOURCE:
    return {
      ...state,
      segmentGamesResource: [],
    };
  case GameActionType.RESET_GAME_PROVIDERS_RESOURCE:
    return {
      ...state,
      providersResource: {
        ...state.providersResource,
        [payload.key]: [],
      },
    };
  case GameActionType.SAVE_BRAND_GAME_PROVIDERS:
    return {
      ...state,
      brandProviders: payload.data,
    };
  case GameActionType.RESET_BRAND_GAME_PROVIDERS:
    return {
      ...state,
      brandProviders: {},
    };
  case GameActionType.SAVE_GAMES_RESOURCE:
    return {
      ...state,
      gamesResource: {
        ...state.gamesResource,
        [payload.key]: payload.data,
      },
    };
  case GameActionType.RESET_GAMES_RESOURCE:
    return {
      ...state,
      gamesResource: {
        ...state.gamesResource,
        [payload.key]: [],
      },
    };
  case GameActionType.SAVE_BRAND_PROVIDER_GAMES:
    return {
      ...state,
      brandProviderGames: payload.data,
    };
  case GameActionType.RESET_BRAND_PROVIDER_GAMES:
    return {
      ...state,
      brandProviderGames: {},
    };
  case GameActionType.SAVE_GAME_PROVIDERS:
    return {
      ...state,
      providers: payload.data,
    };
  case GameActionType.RESET_GAME_PROVIDERS:
    return {
      ...state,
      providers: {},
    };
  case GameActionType.SAVE_PROVIDER_GAMES:
    return {
      ...state,
      providerGames: payload.data,
    };
  case GameActionType.RESET_PROVIDER_GAMES:
    return {
      ...state,
      providerGames: [],
    };

  case GameActionType.SAVE_FREE_GAME_PROVIDERS:
    return {
      ...state,
      freeGameProviders: payload.data,
    };
  case GameActionType.RESET_FREE_GAME_PROVIDERS:
    return {
      ...state,
      freeGameProviders: [],
    };
  case GameActionType.SAVE_FREE_GAMES_V2_PROVIDERS:
    return {
      ...state,
      freeGamesV2Providers: payload.data,
    };
  case GameActionType.RESET_FREE_GAMES_V2_PROVIDERS:
    return {
      ...state,
      freeGamesV2Providers: [],
    };
  case GameActionType.SAVE_FREE_GAMES:
    return {
      ...state,
      freeGames: payload.data,
    };
  case GameActionType.RESET_FREE_GAMES:
    return {
      ...state,
      freeGames: [],
    };
  case GameActionType.SAVE_FREE_GAMES_V2:
    return {
      ...state,
      freeGamesV2: payload.data,
    };
  case GameActionType.RESET_FREE_GAMES_V2:
    return {
      ...state,
      freeGamesV2: [],
    };
  case GameActionType.SAVE_FREE_GAME_AMOUNT_RESTRICTIONS:
    return {
      ...state,
      freeGameAmountRestrictions: payload.data,
    };
  case GameActionType.RESET_FREE_GAME_AMOUNT_RESTRICTIONS:
    return {
      ...state,
      freeGameAmountRestrictions: {},
    };
  case GameActionType.SAVE_FREE_GAMES_V2_AMOUNT_RESTRICTIONS:
    return {
      ...state,
      freeGamesV2AmountRestrictions: payload.data,
    };
  case GameActionType.RESET_FREE_GAMES_V2_AMOUNT_RESTRICTIONS:
    return {
      ...state,
      freeGamesV2AmountRestrictions: [],
    };
  case GameActionType.CHANGE_FREE_GAME_LOADING_STATE:
    return {
      ...state,
      freeGameLoading: payload.state,
    };
  case GameActionType.CHANGE_FREE_GAME_AMOUNT_LOADING_STATE:
    return {
      ...state,
      freeGameAmountLoading: payload.state,
    };
  case GameActionType.SAVE_GAME_PROVIDER:
    return {
      ...state,
      gameProvider: payload.data,
    };
  case GameActionType.RESET_GAME_PROVIDER:
    return {
      ...state,
      gameProvider: {},
    };
  case GameActionType.SAVE_BRAND_JACKPOT_FEEDS:
    return {
      ...state,
      jackpotFeeds: payload.data,
    };
  case GameActionType.RESET_BRAND_JACKPOT_FEEDS:
    return {
      ...state,
      jackpotFeeds: [],
    };
  case GameActionType.SAVE_BRAND_JACKPOT_FEED_GAMES:
    return {
      ...state,
      jackpotFeedGames: payload.data,
    };
  case GameActionType.RESET_BRAND_JACKPOT_FEED_GAMES:
    return {
      ...state,
      jackpotFeedGames: {},
    };
  default:
    return state;
  }
};

export default gameReducer;

import CashbackV2ActionType from 'actions/cashBackV2/cashBackV2ActionType';

const initialState = {
  cashBacksV2: {},
  cashBackV2: {},
  playerCashBacksV2: {},
  playerCashBackV2Periods: {},
  statistics: {},
  players: {},
  includedPlayers: [],
};

const cashBackReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case CashbackV2ActionType.SAVE_CASH_BACKS_V2:
    return {
      ...state,
      cashBacksV2: payload.data,
    };
  case CashbackV2ActionType.RESET_CASH_BACKS_V2:
    return {
      ...state,
      cashBacksV2: {
        data: [],
        total: 0,
      },
    };
  case CashbackV2ActionType.SAVE_CASH_BACK_V2:
    return {
      ...state,
      cashBackV2: payload.data,
    };
  case CashbackV2ActionType.RESET_CASH_BACK_V2:
    return {
      ...state,
      cashBackV2: {},
    };
  case CashbackV2ActionType.SAVE_PLAYER_CASH_BACKS_V2:
    return {
      ...state,
      playerCashBacksV2: payload.data,
    };
  case CashbackV2ActionType.RESET_PLAYER_CASH_BACKS_V2:
    return {
      ...state,
      playerCashBacksV2: {
        data: [],
        total: 0,
      },
    };
  case CashbackV2ActionType.SAVE_PLAYER_CASH_BACK_V2_PERIODS:
    return {
      ...state,
      playerCashBackV2Periods: payload.data,
    };
  case CashbackV2ActionType.RESET_PLAYER_CASH_BACK_V2_PERIODS:
    return {
      ...state,
      playerCashBackV2Periods: {},
    };
  case CashbackV2ActionType.SAVE_CASH_BACK_V2_STATISTICS:
    return {
      ...state,
      statistics: payload.data,
    };
  case CashbackV2ActionType.RESET_CASH_BACK_V2_STATISTICS:
    return {
      ...state,
      statistics: {},
    };
  case CashbackV2ActionType.SAVE_CASH_BACK_V2_PLAYERS:
    return {
      ...state,
      players: payload.data,
    };
  case CashbackV2ActionType.RESET_CASH_BACK_V2_PLAYERS:
    return {
      ...state,
      players: {},
    };
  case CashbackV2ActionType.SAVE_CASH_BACK_V2_INCLUDED_PLAYERS:
    return {
      ...state,
      includedPlayers: payload.data,
    };
  case CashbackV2ActionType.RESET_CASH_BACK_V2_INCLUDED_PLAYERS:
    return {
      ...state,
      includedPlayers: [],
    };
  default:
    return state;
  }
};

export default cashBackReducer;

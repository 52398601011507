const OperatorLimitActionType = {
  GET_LARGE_TRANSACTIONS_GENERAL_LIMITS: 'GET_LARGE_TRANSACTIONS_GENERAL_LIMITS',
  SAVE_LARGE_TRANSACTIONS_GENERAL_LIMITS: 'SAVE_LARGE_TRANSACTIONS_LIMITS',
  RESET_LARGE_TRANSACTIONS_GENERAL_LIMITS: 'RESET_LARGE_TRANSACTIONS_LIMITS',

  EDIT_LARGE_TRANSACTIONS_GENERAL_LIMITS: 'EDIT_LARGE_TRANSACTIONS_GENERAL_LIMITS',

  GET_LARGE_TRANSACTION_LIMITS_LOGS: 'GET_LARGE_TRANSACTION_LIMITS_LOGS',
  SAVE_LARGE_TRANSACTION_LIMITS_LOGS: 'SAVE_LARGE_TRANSACTION_LIMITS_LOGS',
  RESET_LARGE_TRANSACTION_LIMITS_LOGS: 'RESET_LARGE_TRANSACTION_LIMITS_LOGS',

  GET_BRAND_PLAYER_LIMITS: 'GET_BRAND_PLAYER_LIMITS',
  SAVE_BRAND_PLAYER_LIMITS: 'SAVE_BRAND_PLAYER_LIMITS',
  RESET_BRAND_PLAYER_LIMITS: 'RESET_BRAND_PLAYER_LIMITS',

  CHANGE_BRAND_PLAYER_LIMITS: 'CHANGE_BRAND_PLAYER_LIMITS',

  GET_RESPONSIBLE_GAMING_LIMITS: 'GET_RESPONSIBLE_GAMING_LIMITS',
  SAVE_RESPONSIBLE_GAMING_LIMITS: 'SAVE_RESPONSIBLE_GAMING_LIMITS',
  RESET_RESPONSIBLE_GAMING_LIMITS: 'RESET_RESPONSIBLE_GAMING_LIMITS',

  EDIT_RESPONSIBLE_GAMING_LIMITS: 'EDIT_RESPONSIBLE_GAMING_LIMITS',

  GET_LARGE_TRANSACTIONS_WIN_LIMITS: 'GET_LARGE_TRANSACTIONS_WIN_LIMITS',
  SAVE_LARGE_TRANSACTIONS_WIN_LIMITS: 'SAVE_LARGE_TRANSACTIONS_WIN_LIMITS',
  RESET_LARGE_TRANSACTIONS_WIN_LIMITS: 'RESET_LARGE_TRANSACTIONS_WIN_LIMITS',

  EDIT_LARGE_TRANSACTIONS_WIN_LIMITS: 'EDIT_LARGE_TRANSACTIONS_WIN_LIMITS',
};

export default OperatorLimitActionType;
